import React, { useState } from 'react'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import QRcode from '../../assets/images/QRcode.png'
import ConsultationPrescriptionForm from './ConsultationPrescriptionForm';
import { Box, Modal } from '@mui/material';

const TitleValueComponent = ({ title, value }) => {
    return <div className='w-full h-max flex flex-col justify-center items-start gap-0 text-[12px] font-medium'>
        <p className='text-[#999]'>{title}</p>
        <p className='text-[#272d2d]'>{value}</p>
    </div>
}

const ChatStartedComponent = ({ time, issue }) => {
    return <div className='w-full flex flex-col justify-center items-center gap-0 bg-blue-600/10 text-[11px] text-center rounded-[12px] p-2 font-medium'>
        <p className='text-blue-500/50'>{time}, Chat Started</p>
        <p className='text-blue-400'>{issue}</p>
    </div>
}

const ChatBoxLeft = ({ message, time }) => {
    const lines = message.split("\n"); // Split by newline character

    return <div className='relative px-2 pt-1 pb-5 w-max max-w-[90%] ml-auto text-[12px] text-medium text-left bg-[#fff] rounded-[12px] shadow-[0px_0px_10px_rgba(0,0,0,0.05)]'>
        {lines.map((line, index) => (
            <p key={index}>{message}</p> // Render each line separately
        ))}
        <div className='absolute right-3 bottom-1 flex justify-center items-center gap-1'>
            <DoneAllRoundedIcon sx={{ fontSize: '14px', color: '#2299ff' }} />
            <span className='text-[#bbb]'>{time}</span>
        </div>
    </div>
}

const ChatBoxRight = ({ message, time }) => {
    const lines = message.split("\n"); // Split by newline character

    return <div className='relative px-2 pt-1 pb-5 w-max max-w-[90%] mr-auto text-[12px] text-medium text-left bg-[#fff] rounded-[12px] shadow-[0px_0px_10px_rgba(0,0,0,0.05)]'>
        {lines.map((line, index) => (
            <p key={index}>{line}</p> // Render each line separately
        ))}
        <div className='absolute right-3 bottom-1 flex justify-center items-center gap-1'>
            <DoneAllRoundedIcon sx={{ fontSize: '14px', color: '#2299ff' }} />
            <span className='text-[#bbb]'>{time}</span>
        </div>
    </div>
}

const PrescriptionComponent = ({ code, date, prescriptionGenerated, openModal }) => {

    const handleClick = () => {
        if(prescriptionGenerated) {
            openModal();
        }
    }

    return <div className='w-4/5 flex justify-start items-center bg-transparent gap-2'>
        <div className='w-full flex justify-start items-center bg-[#fff] rounded-[12px] p-2 text-[12px] font-medium'>
            <div className='w-full flex flex-col justify-start items-start'>
                <div className='w-full flex gap-2 justify-start items-center'>
                    <p className='w-full capitalize text-[#999] whitespace-nowrap'>prescription code</p>
                    <p className='w-full '>{code}</p>
                </div>
                <div className='w-full flex gap-2 justify-start items-center'>
                    <p className='w-full capitalize text-[#999]'>Date of issue</p>
                    <p className='w-full '>{date}</p>
                </div>
            </div>
            <div className='h-[34px] w-[34px] flex justify-center items-center'>
                <img className={`relative block h-full w-full ${prescriptionGenerated ? 'cursor-pointer' : 'cursor-default'}`} src={QRcode} alt='QR-code' onClick={() => {handleClick()}}/>
            </div>
        </div>
        <FileDownloadRoundedIcon sx={{ fontSize: '16px', color: '#999' }} />
    </div>
}

const PatientInsuranceInfo = ({ ins_number, ins_company_name, ins_type, date }) => {
    return <div className='w-full relative px-3 py-2 font-medium bg-blue-600/10 rounded-[16px] text-[12px] flex flex-col justify-start items-start gap-2'>
        <div className='absolute top-2 right-2'>
            <MoreVertOutlinedIcon sx={{ fontSize: '18px', cursor: 'pointer' }} />
        </div>
        <div className=''>
            <p className='capitalize text-[#aaa]'>number</p>
            <div className='flex items-center justify-start gap-2 text-[13px]'>
                <p className='uppercase font-semibold'>{ins_number}</p>
                /
                <button className='m-0 p-0 border-none outline-none text-blue-600' >{ins_company_name}</button>
                <ContentCopyOutlinedIcon fontSize='12px' sx={{ color: '#2563eb' }} />
            </div>
        </div>
        <div className='w-full flex justify-between items-center'>
            <p className='capitalize font-semibold text-[#666]'>{ins_type}</p>
            <p className='capitalize font-semibold text-[#666]'>{date}</p>
        </div>
    </div>
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 260,
    bgcolor: 'transparent',
    // boxShadow: 24,
    borderRadius: 3,
    p: 0,
    overflow: 'hidden'
};

const ConsultationContentMain = () => {
    const [open, setOpen] = useState(false);
    const [prescriptionGenerated, setPrescriptionGenerated] = useState(false);

    const toggleModal = () => {
        setOpen(!open);
    }

    const generatePrescription = () => {
        setPrescriptionGenerated(true);
    }

    const divStyleUpper = {
        webkitMaskImage: `
          radial-gradient(circle at bottom left, transparent 0, transparent 12px, black 13px),
          radial-gradient(circle at bottom right, transparent 0, transparent 12px, black 13px)
        `,
        webkitMaskPosition: 'bottom left, bottom right',
        webkitMaskRepeat: 'no-repeat',
        webkitMaskSize: '50% 100%',
        maskImage: `
          radial-gradient(circle at bottom left, transparent 0, transparent 12px, black 13px),
          radial-gradient(circle at bottom right, transparent 0, transparent 12px, black 13px)
        `,
        maskPosition: 'bottom left, bottom right',
        maskRepeat: 'no-repeat',
        maskSize: '50% 100%',
    };

    const divStyleLower = {
        webkitMaskImage: `
          radial-gradient(circle at top left, transparent 0, transparent 12px, black 13px),
          radial-gradient(circle at top right, transparent 0, transparent 12px, black 13px)
        `,
        webkitMaskPosition: 'top left, top right',
        webkitMaskRepeat: 'no-repeat',
        webkitMaskSize: '50% 100%',
        maskImage: `
          radial-gradient(circle at top left, transparent 0, transparent 12px, black 13px),
          radial-gradient(circle at top right, transparent 0, transparent 12px, black 13px)
        `,
        maskPosition: 'top left, top right',
        maskRepeat: 'no-repeat',
        maskSize: '50% 100%',
    };

    return (
        <>
            <div className='relative w-full min-h-0 h-full max-h-full flex flex-col gap-4 justify-start items-start py-4 pl-4'>
                <div className='w-full h-full flex justify-center items-center gap-2 p-2 bg-[#ececec] rounded-s-[30px] rounded-tl-[40px] shadow-sm overflow-hidden'>
                    <div className='w-1/2 h-full '>
                        <ConsultationPrescriptionForm openModal={() => {toggleModal(); generatePrescription();}} />
                    </div>
                    <div className='w-1/2 h-full bg-[#fff] rounded-[20px] p-3 flex flex-col gap-2 justify-start items-start'>
                        <h2 className='w-full text-[14px] capitalize font-medium leading-[1]'>Amanda halvorson</h2>
                        <PatientInsuranceInfo ins_number={'bcbs1234567'} ins_company_name={'Blue Shield'} ins_type={'blue cross'} date={'April 27th, 2023'} />
                        <div className='w-full flex flex-col gap-[5px] justify-start items-start'>
                            <div className='w-full flex gap-2 justify-start items-start'>
                                <TitleValueComponent title={'Date of birth'} value={'June 27th, 1989 (34y.o.)'} />
                                <TitleValueComponent title={'Phone number'} value={'272-229-6909'} />
                            </div>
                            <div className='w-full flex gap-2 justify-start items-start'>
                                <TitleValueComponent title={'Gender'} value={'F'} />
                                <TitleValueComponent title={'Email'} value={'a.smith@gmail.com'} />
                            </div>
                            <div className='w-full flex gap-2 justify-start items-start'>
                                <TitleValueComponent title={'Patient ID'} value={'567892_AS_PAT'} />
                                <TitleValueComponent title={'Address'} value={'9040 Karelle Camp. Millertown'} />
                            </div>
                        </div>
                        <div className='relative w-full h-full flex flex-col justify-start items-start gap-1 bg-slate-400/10 rounded-[12px] py-2 px-3'>
                            <div className='w-full flex justify-between items-center'>
                                <h2 className='w-full text-[12px] capitalize font-medium'>chat</h2>
                                <div className='w-max flex gap-1 justify-center items-center'>
                                    <SearchRoundedIcon sx={{ fontSize: '16px', color: '#444' }} />
                                    <DateRangeRoundedIcon sx={{ fontSize: '15px', color: '#444' }} />
                                </div>
                            </div>
                            <div className='w-full h-full flex flex-col gap-2 overflow-auto'>
                                <ChatStartedComponent time={'March 12 12:30'} issue={'Issues with medication, Dizziness for a few hours after taking new medication'} />
                                <ChatBoxLeft 
                                    message={`Hello Dr. Smith, I wanted to check in regarding my medication. I've been experiencing some (dizziness), is that ok? Thanks.`} 
                                    time={'12:32'}
                                />
                                <ChatBoxRight 
                                    message={`Hello Ms. Halvorson, \n dizziness is a common side effect with the medication you are taking. Here's a prescription to combat that. If the dizziness persists or worsen, please let me know so we can discuss potiential alternatives.`} 
                                    time={'12:42'}
                                />
                                <PrescriptionComponent code={'1256'} date={'17.01.2023'} prescriptionGenerated={prescriptionGenerated} openModal={toggleModal} />
                                <ChatBoxLeft 
                                    message={`Ok, I'll try that. Thank you!`} 
                                    time={'12:45'}
                                />
                            </div>
                            <span className='absolute bottom-1 left-[50%] -translate-x-[50%] text-[10px] text-[#bbb] font-medium capitalize'>chat ended</span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={` relative
                            w-full flex justify-start items-center bg-[#fff] text-[12px] font-medium p-4 pb-0 border-none
                        `} style={divStyleUpper}
                    >
                        <div className='w-full flex justify-start items-center pb-4 border-b-[1px] border-b-[#ccc]'>
                            <div className='w-full flex flex-col gap-1 justify-start items-start'>
                                <div className='w-full flex justify-start items-center flex-wrap'>
                                    <p className='w-full capitalize text-[#999] whitespace-nowrap'>prescription code</p>
                                    <p className='w-full '>1256</p>
                                </div>
                                <div className='w-full flex justify-start items-center flex-wrap'>
                                    <p className='w-full capitalize text-[#999]'>Date of issue</p>
                                    <p className='w-full '>17.01.2023</p>
                                </div>
                            </div>
                            <div className='h-[50px] w-[60px] flex justify-center items-center'>
                                <img className='relative block h-full w-full' src={QRcode} alt='QR-code' />
                            </div>
                        </div>
                    </div>
                    <div className={` relative
                            w-full flex justify-start items-center bg-[#fff] p-4 pb-0 text-[12px] font-medium border-none before:border-none
                        `} 
                        style={divStyleLower}
                    >
                        <div className='w-full flex justify-start items-center pb-4 border-b-[1px] border-b-[#ccc]'>
                            <TitleValueComponent title={'Issued by'} value={'Dr. Samatha Smith'} />
                        </div>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start gap-1 bg-[#fff] p-4 text-[12px] font-medium'>
                        <p className='w-full capitalize text-[#999] whitespace-nowrap'>Medication and dosage</p>
                        <div className='flex flex-col justify-start items-start gap-2 border-s-2 border-[#00ff00] pl-2 text-[12px]'>
                            <p className='text-[#272d2d]'>Crestorh, filmed tablets, 20mg</p>
                            <div className=''>
                                <p className='text-[#444]'>1 morning x 10 days</p>
                                <p className='text-[#444]'>Total: (10 tab)</p>
                            </div>
                            <p className='text-[#999]'>Payment: 0%</p>
                            <p className='text-[#272d2d]'>Valid till: 17.06.2023</p>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ConsultationContentMain;
