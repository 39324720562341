import React from 'react'

const ButtonWithCenterImage = ({ active, image, altText }) => {
  return (
    <div className='max-h-full'>
      <a className={`relative flex w-8 h-8 overflow-hidden rounded-full ${active ? 'bg-[#F3FF47]' : 'bg-white'}`} href='/'>
        <img className='relative block h-full w-full' src={image} alt={altText} />
      </a>
    </div>
  )
}

export default ButtonWithCenterImage
