import { createSlice } from '@reduxjs/toolkit';

export const overviewTabsSlice = createSlice({
	name: 'overviewTabState',
	initialState: {
		overviewTabState: 'diagnosis',
	},
	reducers: {
		changeOverviewTabState: {
			reducer: (state, action) => {
				state.overviewTabState = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		}
	}
})

// each case under reducers becomes an action
export const { changeOverviewTabState } = overviewTabsSlice.actions

export default overviewTabsSlice.reducer