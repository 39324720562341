import { createSlice } from '@reduxjs/toolkit';

export const navSlice = createSlice({
	name: 'navState',
	initialState: {
		navState: 'overview',
		previousNavState: null
	},
	reducers: {
		changeNavState: {
			reducer: (state, action) => {
				state.navState = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		},
		setPreviousNavState: {
			reducer: (state, action) => {
				state.previousNavState = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		}
	}
})

// each case under reducers becomes an action
export const { changeNavState, setPreviousNavState } = navSlice.actions

export default navSlice.reducer