import { configureStore } from '@reduxjs/toolkit';
import navSlice from '../slices/navSlice';
import overviewTabsSlice from '../slices/overviewTabsSlice';
import docsTabsSlice from '../slices/docsTabsSlice';
import activePatientAnatomySlice from '../slices/activePatientAnatomySlice';
import drawerSlice from '../slices/drawerSlice';
import combinedYearTimelineReducers from '../slices/yearTimelineSlice';
import formDrawerSlice from '../slices/formDrawerSlice';
import authReducer from '../slices/authSlice.js';

const store = configureStore({
	reducer: {
		auth: authReducer,
		navSlice: navSlice,
		overviewTabSlice: overviewTabsSlice,
		docsTabsSlice: docsTabsSlice,
		activePatientAnatomySlice: activePatientAnatomySlice,
		yearTimelineReducer: combinedYearTimelineReducers,
		drawerSlice: drawerSlice,
		formDrawerSlice: formDrawerSlice,
	},
})

export default store;