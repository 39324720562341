import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

const BubbleTimelineGraph = ({ idCount, fillColor, strokeColor, yearTextColor, active, data, thumbValues }) => {
    // Use thumbValues to dynamically change colors or perform any other logic
    const [intervalValue, setIntervalValue] = useState(thumbValues);

    function getRadiusValue(category) {
        if (category === 'low') {return 5}
        else if (category === 'medium') {return 7}
        else if (category === 'high') {return 9}
        else {return 12}
    }

    function drawChart(data) {
        const year = Object.keys(data)[0];
        const obj = Object.values(data)[0];
        
        const divElement = document.getElementById(`timelineChart${idCount}`);
        divElement.innerHTML = '';
        const svg = d3.select(`#timelineChart${idCount}`).append("svg")
            .attr('id',`Chart${idCount}`).attr('class',`overflow-visible`).attr("width", 100).attr("height", 52);
        // if(active) {
        svg.append('text')
            .text(function(data) { return(new Date(obj[obj.length - 1].date).getFullYear());})
            .attr('x', '0%')
            .attr('y', '88%')
            .attr('fill', yearTextColor)
            .attr('class', `text-[10px] font-light`);
        // }

        // svg.append('text')
        //     .text(function(data) { return(new Date(obj[obj.length - 1].date).getFullYear() - 1);})
        //     .attr('x', `${active ? '88%' : '75%'}`)
        //     .attr('y', '88%')
        //     .attr('class', 'text-[10px] font-light');

        // Get the value of the svg to for scaleLinear
        function getLineVal(val) {
            if(val === 'max') {
                let el = document.getElementById(`Chart${idCount}`);
                return el.getBoundingClientRect().width;
            }
            else {
                return 0;
            }
        }
        // Convert to UNIX timestamp
        function convertToTimeStamp(date) {
            let parts = date.match(/(\d{4})-(\d{2})/);
            return new Date(parts[1]+ '-'+parts[2]+'-01').getTime();
        }

        // Function to calculate scaled value relative to the month of the year
        function getScaledValue(date) {
            const timestamp = convertToTimeStamp(date);
            const monthOfYear = new Date(timestamp).getMonth(); // Get the month of the year (0 for January, 11 for December)
            return scaleLine(monthOfYear);
        }
    
        let scaleLine = d3.scaleLinear()
            .domain([0, 12])
            .range([getLineVal('max') , getLineVal('min')]);
    
        let allGroups = svg.selectAll('g').data(obj);
        let group = allGroups.enter().append('g').attr('id', function(data){return 'group-' + obj.id});
    
        group.append('circle')
            .attr('cx', function(data) {return getScaledValue(data.date);})
            .attr('cy', '40%')
            .attr('r', function(data) {return getRadiusValue(data.category)})
            .attr('fill', fillColor)
            .attr('fill-opacity', 1)
            .attr('stroke', strokeColor)
            .attr('stroke-width', 1.5)
            .attr('class', function(data) { return('circle-category circle-' + data.category.toLowerCase())})
            .attr('id', function(data) {
                return 'circle-' + data.id
            })
            // When hover a circle
            .on('mouseover', function(d, i) {
                d3.select(this).attr('r', function(data) {return getRadiusValue(data.category)});
                d3.select(this).classed('circle-hovered', true);
                d3.select(this.parentNode).selectAll('text').style('opacity', 1);
                d3.select(this.parentNode).selectAll('.text-place').classed('hovered', true).style('opacity', 0);
                d3.select(this.parentNode).selectAll('.text-desc').classed('hovered', true).style('opacity', 0);
                d3.select(this.parentNode).selectAll('.text-date-end').classed('hovered', true).style('opacity', 0);
            })
            // When un-hover a circle
            .on('mouseout', function(d, i){
                d3.select(this).attr('r', function(data) {return getRadiusValue(data.category)});
                d3.select(this).classed('circle-hovered', false);
                d3.select(this.parentNode).selectAll('text').style('opacity', 0);
            });

        group.append('text')
            .style('opacity', 0)
            .text(function(data) { return(data.name);})
            .attr('x', function(data) {
                let elementWitdh = this.getBoundingClientRect().width;
                // Avoid overflow
                if(scaleLine(convertToTimeStamp(data.date)) + elementWitdh >= getLineVal('max')) {
                    return scaleLine(convertToTimeStamp(data.date)) - elementWitdh;
                }
                else {
                    return scaleLine(convertToTimeStamp(data.date));
                }
            })
            .attr('y', 40)
            .attr('class', 'text-position');
    
        group.append('text')
            .text(function(data) {
                // Get only YYYY-MM
                if(data.date.length > 7) {
                return (data.startDate.slice(0,7))
                }
                else {
                return(data.startDate)
                }
            })
            .attr('x', function(data) {
                // Get sibling to have the len and align the date
                let elementWitdh= this.getBoundingClientRect().width;
                let positionWidth = this.parentNode.querySelector('text.text-position').getBoundingClientRect().width;
                if(scaleLine(convertToTimeStamp(data.date)) + positionWidth >= getLineVal('max')) {
                return scaleLine(convertToTimeStamp(data.date)) - elementWitdh;
                }
                else {
                return scaleLine(convertToTimeStamp(data.date));
                }
            })
            .attr('y', 30)
            .attr('class', 'text-date')
            .style('opacity', 0);
    }

    useEffect(() => {
        drawChart(data);
    });

    useEffect(() => {
        drawChart(data);
    }, [intervalValue]);

    return (
        <div id={`timeline${idCount}`} className='px-0'>
            <div id={`timelineChart${idCount}`}></div>
        </div>
    )
}

export default BubbleTimelineGraph
