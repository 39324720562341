import React, { useRef } from 'react'
import CloseIconButton from './CloseIconButton'
import TitleSubtitleComponent from './TitleSubtitleComponent'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';

const ReportImagesCard = ({
    childId,
    name, 
    subTitle, 
    xPos,
    yPos,
    parentWidth,
    parentheight,
    parentBgColor,
    roundedSize,
    reportImages,
    dismissChild
}) => {
    const divRef = useRef(null); // Reference to the div

    const handleDismiss = (childId) => {
        dismissChild(childId);
        if (divRef.current) {
            divRef.current.style.display = 'none'; // Hide the div
        }
    };

    return (
        <div ref={divRef} className={`
            flex flex-col items-center justify-start absolute z-[999]
            ${parentWidth ? parentWidth : 'w-full'}
            ${parentheight ? parentheight : 'h-full'}
            ${parentBgColor ? parentBgColor : 'bg-transparent'}
            ${roundedSize ? roundedSize : 'rounded-[30px]'}
        `} style={{ top: yPos, left: xPos }}>
            <CloseIconButton 
                customParentClasses={'!absolute -top-1 -left-1'}
                parentWidth={'w-max'} 
                width={'w-[30px]'} 
                height={'h-[30px]'}
                iconFontSize={14} 
                iconColor={'#272d2d'}
                bgColor={'bg-[#f3ff47]'}
                clickHandler={() => {handleDismiss(childId)}}
            />
            <div className='w-full pl-10 pr-2 flex justify-between items-center'>
                <TitleSubtitleComponent 
                    parentFlexGap = {'gap-[3px]'}
                    parentPadding={'py-2'}
                    titleText={name} 
                    subText={subTitle} 
                    titleFontSize={'text-[12px]'} 
                    subTitleFontSize={'text-[10px]'} 
                    titleFontWeight = {'font-[400]'}
                    subTitleFontWeight = {'font-[400]'}
                    subTitleTextColor = {'text-[#666]'}
                />
                <ButtonWithCenterIcon 
                    parentWidth={'w-max flex items-center justify-center'} 
                    width={'w-[30px]'} 
                    height={'h-[30px]'} 
                    icon={PushPinOutlinedIcon} 
                    iconFontSize={14} 
                    iconColor={'#444'}
                    bgColor={'bg-transparent'}
                    clickHandler={() => {}} 
                />
            </div>
            {reportImages.length > 0 ?
                reportImages.length > 4 ?
                    ''
                    :
                reportImages.length === 4 ?
                    <>
                        <div className='w-full h-full flex flex-wrap justify-between items-center rounded-tl-[30px] rounded-tr-[30px] overflow-hidden'>
                            <div className='w-1/2 h-full bg-[#f9f9f9] rounded-br-[30px] overflow-hidden'>
                                <LightGallery
                                    elementClassNames='w-full h-full'
                                    speed={200}
                                    plugins={[lgThumbnail, lgZoom]}
                                >
                                    <a href={reportImages[0]} data-src={reportImages[0]}>
                                        <img className='relative w-full h-full object-cover object-center' src={reportImages[0]} alt="x-ray" />
                                    </a>
                                </LightGallery>
                            </div>
                            <div className='w-1/2 h-full bg-[#f9f9f9] rounded-bl-[30px] overflow-hidden'>
                                <LightGallery
                                    elementClassNames='w-full h-full'
                                    speed={200}
                                    plugins={[lgThumbnail, lgZoom]}
                                >
                                    <a href={reportImages[1]} data-src={reportImages[1]}>
                                        <img className='relative w-full h-full object-cover object-center' src={reportImages[1]} alt="x-ray" />
                                    </a>
                                </LightGallery>
                            </div>
                        </div>
                        <div className='w-full h-full flex flex-wrap justify-between items-center rounded-bl-[30px] rounded-br-[30px] overflow-hidden'>
                            <div className='w-1/2 h-full bg-[#f9f9f9] rounded-tr-[30px] overflow-hidden'>
                                <LightGallery
                                    elementClassNames='w-full h-full'
                                    speed={200}
                                    plugins={[lgThumbnail, lgZoom]}
                                >
                                    <a href={reportImages[2]} data-src={reportImages[2]}>
                                        <img className='relative w-full h-full object-cover object-center' src={reportImages[2]} alt="x-ray" />
                                    </a>
                                </LightGallery>
                            </div>
                            <div className='w-1/2 h-full bg-[#f9f9f9] rounded-tl-[30px] overflow-hidden'>
                                <LightGallery
                                    elementClassNames='w-full h-full'
                                    speed={200}
                                    plugins={[lgThumbnail, lgZoom]}
                                >
                                    <a href={reportImages[3]} data-src={reportImages[3]}>
                                        <img className='relative w-full h-full object-cover object-center' src={reportImages[3]} alt="x-ray" />
                                    </a>
                                </LightGallery>
                            </div>
                        </div>
                    </>
                    :
                reportImages.length > 1 ?
                    <div className={`w-full h-full flex justify-center items-center gap-[2px] rounded-[30px] overflow-hidden`}>
                        {
                            reportImages.map((item, i) => (
                                <div key={i} className='w-full h-full flex justify-center items-center'>
                                    <LightGallery
                                        elementClassNames='w-full h-full'
                                        speed={200}
                                        plugins={[lgThumbnail, lgZoom]}
                                    >
                                        <a href={item} data-src={item}>
                                            <img className='relative w-full h-full object-cover object-center' src={item} alt="x-ray" />
                                        </a>
                                    </LightGallery>
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div className={`w-full h-full flex justify-center items-center gap-[2px] rounded-[30px] overflow-hidden`}>
                        {
                            reportImages.map((item, i) => (
                                <div key={i} className='w-full h-full flex justify-center items-center'>
                                    <LightGallery
                                        elementClassNames=''
                                        speed={200}
                                        plugins={[lgThumbnail, lgZoom]}
                                    >
                                        <a href={item} data-src={item}>
                                            <img className='relative w-full h-full object-cover object-center' src={item} alt="x-ray" />
                                        </a>
                                    </LightGallery>
                                </div>
                            ))
                        }
                    </div>
                :
                ''
            }
        </div>
    )
}

export default ReportImagesCard
