import React from 'react'
import AllPatientsBar from './AllPatientsBar'
import PatientProfileReport from './PatientProfileReport'
import { useSelector } from 'react-redux'
import DiscussionRoomSidebar from '../doctorComponents/DiscussionRoomSidebar'

const PatientsSidebar = () => {
  const navState = useSelector((state) => state.navSlice.navState); // Getting navstate value from the store

  return (
    <div className={`h-full w-1/6 flex flex-col justify-start items-center gap-4`}>
        <AllPatientsBar />
        {navState !== 'communication' ?
          <PatientProfileReport />
          :
          <DiscussionRoomSidebar />
        }
    </div>
  )
}

export default PatientsSidebar
