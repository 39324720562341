import authService from '../services/authService'; // Import AuthService
import { loginSuccess, logout } from '../slices/authSlice'; // Redux actions
import { isTokenExpired } from '../utils/authutils';

// Redux thunk for handling login
export const login = (username, password) => async (dispatch) => {
  try {
    const token = await authService.login(username, password); // Get token from authService
    localStorage.setItem('authToken', token); // Store the token in localStorage
    dispatch(loginSuccess(token)); // Dispatch Redux action to update auth state
  } catch (error) {
    console.error('Login failed:', error);
    // Handle login failure (e.g., dispatch a failure action or show an error message)
  }
};

// Redux thunk for handling logout
export const performLogout = () => (dispatch) => {
    authService.logout(); // Call authService to clear the token
    dispatch(logout()); // Dispatch Redux action to clear auth state
};

export const restoreAuthFromToken = () => (dispatch) => {
    const token = localStorage.getItem('authToken'); // Retrieve token from localStorage
    if (!token || isTokenExpired(token)) {
        authService.logout(); // If no token or expired, logout
        dispatch(logout()); // Dispatch Redux logout action
    } else {
        dispatch(loginSuccess(token)); // If valid, dispatch login success
    }
};