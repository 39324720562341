import React from 'react'

const CustomChips = ({ width, height, rounded, bgColor, fontSize, textColor, padding, shadow, customClasses, children  }) => {
  return (
    <div className={`
        ${customClasses ? customClasses : ''} 
        ${width ? width : 'w-max'} 
        ${height ? height : 'h-max'} 
        ${rounded ? rounded : 'rounded-[20px]'} 
        ${bgColor ? bgColor : 'bg-[#ececec]'} 
        ${fontSize ? fontSize : 'text-[12px]'} 
        ${textColor ? textColor : 'text-[#333]'} 
        ${padding ? padding : 'py-[10px] px-[20px]'} 
        ${shadow ? shadow : 'shadow-sm'}
    `}>
      {children}
    </div>
  )
}

export default CustomChips
