import React from 'react';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={5} dx={10} textAnchor="end" fontSize={10} fill="#666">
          {(payload.value + 1) % 3 === 0 ? payload.value + 1 : ''}
        </text>
      </g>
    );
};

const CustomTimeline = React.forwardRef(({ parentDivId, parentDivHeight, customIdentifier, graphData, graphMargins, ticks, onClickLabelListener, showAxisLine, showTicks, showTickLine }, ref) => {

    const CustomLabel = props => {
        return (
            graphData[props.index].data.map((item, i) => {
                return (
                <foreignObject 
                    key = {`customIdentifier${item.label}${i}`}
                    className="relative label-wrapper overflow-visible" 
                    width = {1} 
                    height = {26} 
                    x = {props.x - 13} 
                    y = {i === 0 ? props.y - 20 : props.y - ((i+1)*24)}
                    onClick={onClickLabelListener ? onClickLabelListener() : () => {}}
                >
                    <div xmlns="http://www.w3.org/1999/xhtml" 
                        className='absolute top-0 left-0 w-max h-full rounded-[20px] bg-[#fff] p-[2px] flex justify-between items-center'
                    >
                        <div className="custom-label bg-[#272d2d] rounded-full w-max h-full p-[8px] flex justify-center items-center peer cursor-pointer">
                            {item.icon}
                        </div>
                        <p className='w-0 text-[0px] peer-hover:px-[5px] peer-hover:text-[10px] peer-hover:w-max transition-all duration-200'>
                            {item.label}
                        </p>
                    </div>
                </foreignObject>
                )
            })
        );
    };

    return (
        <div className={`w-full ${parentDivHeight ? parentDivHeight : 'h-[140px]'}`} id={parentDivId} ref={ref}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart key={(props) => props.index + ticks[props.index]} data={graphData} margin={graphMargins ? graphMargins : {}}>
                
                <XAxis 
                    dataKey="position"
                    domain={['auto','auto']} 
                    scale='time' 
                    ticks={ticks} 
                    interval={0}
                    type='number' 
                    axisLine={showAxisLine ? showAxisLine : false} 
                    mirror={true} 
                    tick={showTicks ? <CustomizedAxisTick /> : false}
                    tickLine={showTickLine ? showTickLine : false}
                    tickFormatter = {(date) => moment(date).format('MMM DD')}
                />

                <YAxis domain={[0, 100]} axisLine={false} tickLine={false} tick={false} hide={true} />

                <Bar dataKey={'amt'} barSize={0.75} isAnimationActive={false} onMouseOver={() => {}} fill="#bbb">
                    <LabelList content={<CustomLabel />} />
                </Bar>

                </BarChart>
            </ResponsiveContainer>
        </div>
    )
});

export default CustomTimeline
