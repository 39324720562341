import React, { useCallback, useEffect, useRef, forwardRef } from 'react'
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ArcherElement } from 'react-archer';
import { useDispatch, useSelector } from 'react-redux';
import { changeFormDrawerState } from '../../slices/formDrawerSlice';

const FormDrawer = forwardRef(({ isOpen, functionToRun, renderArrow, children }, ref) => {
    const formDrawerRef = useRef(null);
    const formDrawerState = useSelector((state) => state.formDrawerSlice.formDrawerState);
    const formDrawerDispatch = useDispatch();

    const toggleFormDrawer = () => {
        formDrawerDispatch(changeFormDrawerState(!formDrawerState));
    }
    
    return (
        <div ref={formDrawerRef} className={`bg-gradient-to-b from-[#DFDFD7] to-[#E4E4DC] h-full 
            absolute z-[999] top-0 right-0 -bottom-[5%] transition-all duration-500
            ${isOpen ? 'left-[0]' : 'left-[100%]'}
        `}>
            <div className='bg-[#ececec] w-full h-full rounded-s-[30px] rounded-tl-[40px] shadow-sm overflow-hidden'>
                {renderArrow ? 
                    <ArcherElement id={'formDrawer'} >
                        <div className='absolute top-0 left-0 z-[999]'>
                            <ButtonWithCenterIcon icon={CloseRoundedIcon} bgColor={'bg-[#f3ff47]'} clickHandler={() => {toggleFormDrawer()}} />
                        </div>
                    </ArcherElement>
                    :
                    <div className='absolute top-0 left-0 z-[999]'>
                        <ButtonWithCenterIcon icon={CloseRoundedIcon} bgColor={'bg-[#f3ff47]'} clickHandler={() => {}} />
                    </div>
                }
                { children }
            </div>
        </div>
    )
});

export default FormDrawer
