import React from 'react'

const TitleSubtitleComponent = ({ 
        parentHeight, parentWidth, parentBgColor, parentFlexGap, parentPadding,
        titleTextColor, titleFontSize, titleFontWeight, 
        subTitleTextColor, subTitleFontSize, subTitleFontWeight, titleText, subText, 
        showHeadingOnTop, alignItemsDirection
    }) => {
    return (
        <div className={`flex flex-col justify-center 
            ${alignItemsDirection ? alignItemsDirection : 'items-start'}
            ${parentFlexGap ? parentFlexGap : 'gap-0'}
            ${parentHeight ? parentHeight : ''}
            ${parentWidth ? parentWidth : 'w-full'}
            ${parentBgColor ? parentBgColor : 'bg-transparent'}
            ${parentPadding ? parentPadding : ''}
        `}>
            {showHeadingOnTop && 
                <p className={`capitalize p-0 m-0 leading-none whitespace-nowrap 
                    ${titleFontSize ? titleFontSize : 'text-[12px]'}
                    ${titleTextColor ? titleTextColor : 'text-[#272d2d]'}
                    ${titleFontWeight ? titleFontWeight : 'font-normal'}
                `}>
                    {titleText}
                </p>
            }
            <p className={`font-light p-0 m-0 leading-none capitalize
                ${subTitleFontSize ? subTitleFontSize : 'text-[10px]'}
                ${subTitleTextColor ? subTitleTextColor : 'text-[#272d2d]'}
                ${subTitleFontWeight ? subTitleFontWeight : 'font-normal'}
            `}>
                {subText}
            </p>
            {!showHeadingOnTop && 
                <p className={`capitalize p-0 m-0 leading-none whitespace-nowrap 
                    ${titleFontSize ? titleFontSize : 'text-[12px]'}
                    ${titleTextColor ? titleTextColor : 'text-[#272d2d]'}
                    ${titleFontWeight ? titleFontWeight : 'font-normal'}
                `}>
                    {titleText}
                </p>
            }
        </div>
    )
}

export default TitleSubtitleComponent
