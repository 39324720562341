import React, { useRef, useState } from 'react'
import OverviewTabBar from './OverviewTabBar'
import OverviewTabContent from './OverviewTabContent'
import PatientAnatomyWrapper from './PatientAnatomyWrapper';
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import BubbleTimelineGraphWrapper from '../BubbleTimelineGraphWrapper';
import { ArcherContainer } from 'react-archer';
import { useDispatch, useSelector } from 'react-redux';
import { changeNavState, setPreviousNavState } from '../../slices/navSlice';
import DocsTabBar from './DocsTabBar';
import { changeDrawerState } from '../../slices/drawerSlice';
import FormDrawer from '../reusableComponents/FormDrawer';
import { formContentTypesData } from '../../utils/formContentTypesdata';
import ConsultationContentMain from '../consultationComponents/ConsultationContentMain';

const childMenuData = 
  {
    'overview' : <OverviewTabBar />, 
    'notes': <></>, 
    'docs': <DocsTabBar />, 
    'labs': <></>, 
    'imagines': <></>, 
    'communication': <></>
  }

const PatientDetailedData = () => {
  const ref = useRef(null);
  const drawerStateDispatch = useDispatch();
  const drawerState = useSelector((state) => state.drawerSlice.drawerState);
  const formDrawerState = useSelector((state) => state.formDrawerSlice.formDrawerState);
  const formDrawerContentType = useSelector((state) => state.formDrawerSlice.formDrawerContentType);
  const navState = useSelector((state) => state.navSlice.navState); // Getting navstate value from the store
  const previousNavState = useSelector((state) => state.navSlice.previousNavState); // Getting navstate value from the store
	const navStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux
  const previousNavStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux
  const activeAnatomy = useSelector((state) => state.activePatientAnatomySlice.activePatientAnatomy); // Getting patientActiveAnatomy value from the store

  const toggleDrawer = (newOpen = !drawerState, type = navState) => {
    if (newOpen) {
      drawerStateDispatch(changeDrawerState(newOpen));
      if (type !== navState) {
        navStateDispatch(changeNavState(type));
        previousNavStateDispatch(setPreviousNavState(navState));
      }
    } else {
      drawerStateDispatch(changeDrawerState(!drawerState));
      if (previousNavState !== null) {
        navStateDispatch(changeNavState(previousNavState));
      }
    }
  };

  return (
    <div className='w-full min-h-0 h-full max-h-full flex flex-col gap-4 justify-start items-start py-4 pl-4'>
      <ArcherContainer className={`w-full h-[calc(100%-70px)]`} svgContainerStyle={{ zIndex: '9' }} strokeColor="#ececec" strokeWidth={1.2} endMarker={false}>
        <div className='w-full h-full max-h-full flex justify-start items-start gap-2'>
          <div className='w-[28%] h-full max-h-full flex justify-center items-center'>
            <PatientAnatomyWrapper 
              ref={ref} 
              defaultExpand={true} 
              relations = {
                [
                  {
                    targetId: 'heartFailure',
                    targetAnchor: 'left',
                    sourceAnchor: 'right',
                  }
                ]
              }
            />
          </div>
          <div className='relative w-[72%] h-full max-h-full flex flex-col justify-start items-start gap-3'>
            {childMenuData[navState]}
            <OverviewTabContent  ref={ref} openDrawer={drawerState} toggleDrawer={toggleDrawer} />

            <FormDrawer isOpen={formDrawerState} functionToRun={() => {}} ref={ref} renderArrow={formDrawerState}>
              {formContentTypesData[formDrawerContentType]}
            </FormDrawer>
          </div>
        </div>
      </ArcherContainer>
      <div className='h-[54px] w-full pr-4'>
        <div className='w-full h-full text-[12px] rounded-[100px] p-[1px] bg-[#ececec] overflow-hidden flex items-stretch gap-2 ml-auto'>
          <div className='w-full'>
            <BubbleTimelineGraphWrapper />
          </div>
          <div className='h-full flex justify-center items-center'>
            <ButtonWithCenterIcon width={'w-[52px]'} height={'h-[52px]'} icon={AddRoundedIcon} iconFontSize={24} />
            <ButtonWithCenterIcon width={'w-[52px]'} height={'h-[52px]'} icon={RemoveRoundedIcon} iconFontSize={24} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientDetailedData
