import React, { forwardRef } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const CloseIconButton = forwardRef(({
    parentWidth, 
    parentHeight, 
    width, 
    height, 
    bgColor, 
    iconFontSize, 
    iconColor ,
    customParentClasses, 
    customButtonClasses, 
    clickHandler 
}, ref) => {
  return (
    <div ref={ref} className={`
        ${parentWidth ? parentWidth : 'w-full'} 
        ${parentHeight ? parentHeight : 'h-full'} 
        ${customParentClasses ? customParentClasses : ''} 
        max-h-full 
    `}>
        <button 
          onClick={clickHandler ? () => clickHandler() : ()=> {}} 
          className={`relative flex 
            ${width ? `${width}` : 'w-8'} 
            ${width ? `${height}` : 'h-8'} 
            ${bgColor ? `${bgColor}` : 'bg-white'} 
            ${customButtonClasses ? customButtonClasses : '' } 
            overflow-hidden rounded-full flex justify-center items-center 
        `}>
            <CloseRoundedIcon style={{ 'fontSize': iconFontSize ? iconFontSize : '16px', 'color': iconColor ? iconColor : '#666' }} />
        </button>
    </div>
  )
});

export default CloseIconButton
