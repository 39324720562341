import React from 'react'

const ComplaintCards = ({ 
    active, 
    parentWidth, 
    parentHeight, 
    parentBgcolor, 
    textColor, 
    date, 
    title, 
    titleFontSize, 
    description, 
    descriptionFontSize 
    }) => {
        return (
            <div className={`
                ${parentWidth ? parentWidth : 'w-full'} 
                ${parentHeight ? parentHeight : 'h-full'}
                ${active ? 'opacity-100' : 'opacity-40'} 
                ${parentBgcolor ? parentBgcolor : 'bg-[#fafafa]'} 
                ${textColor ? textColor : 'text-[#333]'}
                relative flex flex-col gap-2 justify-start items-start px-5 py-3 rounded-[30px] shadow-[0px_0px_2px_rgba(0,0,0,0.1)]
            `}>
                <div className='w-full flex justify-between items-center'>
                    <p className={`${titleFontSize ? titleFontSize : 'text-[14px]'} capitalize`}>
                        {title}
                    </p>
                    <p className={`w-max ${descriptionFontSize ? descriptionFontSize : 'text-[10px]'}`}>{date}</p>
                </div>
                <div className='w-full '>
                    <p className={`${descriptionFontSize ? descriptionFontSize : 'text-[10px]'} capitalize`}>
                        {description}
                    </p>
                </div>
            </div>
        )
}

export default ComplaintCards
