import React from 'react'
// import CustomSlider from './CustomSlider'
import image from '../../assets/images/png-image.png'
import image1 from '../../assets/images/man_portrait_1-removebg-preview.png'
import image2 from '../../assets/images/man_portrait_2-removebg-preview.png'
import image3 from '../../assets/images/man_portrait_3-removebg-preview.png'
import image4 from '../../assets/images/girl_portrait_1.png'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import ButtonWithCenterImage from '../ButtonWithCenterImage';

const imageStore = [image, image1, image2, image3, image4]

const SliderItems = ({ image, active }) => {
  return (
    <ButtonWithCenterImage active={active} image={image} alt={'pateint-profile'} />
  )
};

const slides =Array.from({ length: 5 }).map((item, i) => {
  return <SliderItems key={i} image={imageStore[i]} active={i === 0 ? true : false} />
})

const AllPatientsBar = () => {
  return (
    <div className='text-[12px] rounded-[100px] min-h-[34px] p-[1px] bg-[#ececec] overflow-hidden flex'>
      {slides}
      {/* <CustomSlider slides={slides} /> */}
      <ButtonWithCenterIcon icon={AddRoundedIcon} />
    </div>
  )
}

export default AllPatientsBar
