import { combineReducers, createSlice } from '@reduxjs/toolkit';

export const yearTimelineSlice = createSlice({
	name: 'yearTimelineState',
	initialState: {
		yearTimelineState: (new Date(2023, 0)).getTime(),
	},
	reducers: {
		changeYearTimelineState: {
			reducer: (state, action) => {
				state.yearTimelineState = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		}
	}
});

export const yearTimelineDurationSlice = createSlice({
	name: 'yearTimelineDuration',
	initialState: {
		yearTimelineDurationState: [0, 11],
	},
	reducers: {
		changeYearTimelineDurationState: {
			reducer: (state, action) => {
				state.yearTimelineDurationState = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		}
	}
});

// each case under reducers becomes an action
export const { changeYearTimelineState } = yearTimelineSlice.actions;
export const { changeYearTimelineDurationState } = yearTimelineDurationSlice.actions;

export default combineReducers({ 
	yearTimelineState: yearTimelineSlice.reducer,
	yearTimelineDurationState: yearTimelineDurationSlice.reducer
});