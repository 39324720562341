import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // For decoding JWT tokens

// Service for handling authentication-related API calls
const authService = {
  // Function to log in with username and password
  async login(username, password) {
    const response = await axios.post('https://vera.quantis-labs.com/api/token/', { username, password }); // API call to login
    const token = response.data.access; // Retrieve the token from the response
    return token; // Return the token to the caller
  },

  // Function to check if a token is valid or has expired
  isTokenExpired(token) {
    if (!token) return true; // If there's no token, consider it expired

    try {
      const decodedToken = jwtDecode(token); // Decode the token
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decodedToken.exp < currentTime; // True if token is expired
    } catch (error) {
      console.error('Failed to decode token:', error);
      return true; // If decoding fails, consider it expired
    }
  },

  // Function to log out by clearing the token
  logout() {
    localStorage.removeItem('authToken'); // Clear the token from storage
  },
};

export default authService;
