import React, { useEffect, useRef, useState } from 'react'
import PatientAnatomyWrapper from '../patientComponents/PatientAnatomyWrapper'
import { ArcherContainer } from 'react-archer';
import doctorImage01 from '../../assets/images/doctorImage01.png'
import doctorImage03 from '../../assets/images/doctorImage03.png'
import doctorImage04 from '../../assets/images/doctorImage04.png'
import xRayImage from '../../assets/images/Xray_share.jpeg';
import DoctorTestExams from './DoctorTestExams';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DoctorDiscussionQuickNotes from './DoctorDiscussionQuickNotes';
import { useSelector } from 'react-redux';

const doctorReportExamsData = [
    {
        anatomyType: 'heart' ,
        name: 'Dr. Green', 
        type: 'surgeon', 
        doctorIcon: doctorImage03, 
        reportTitle: 'surgery', 
        reportDescription: 'look what i found during the operation. it looks like a thickening of the ventricular pericardial artery', 
        justifyDirection: 'left',
        reports: [
            {
                icon: BlurOnIcon,
                reportId: 'report1',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Heart MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage, xRayImage]
                    },
                    {
                        dataId: 'child2',
                        name: 'ultrasound scan',
                        value: '02.25',
                        image: [xRayImage]
                    },
                ]
            },
            {
                icon: ScienceOutlinedIcon,
                reportId: 'report2',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Heart MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage]
                    },
                ]
            }
        ]
    },
    {
        anatomyType: 'heart' ,
        name: 'Dr. Maccami', 
        type: 'cardilogist', 
        doctorIcon: doctorImage01, 
        reportTitle: 'purpose', 
        reportDescription: 'i propose to prescribe treatment with binimentinib for prescribed weeks until complete recovery.', 
        justifyDirection: 'right',
        reports: [
            {
                icon: TollOutlinedIcon,
                reportId: 'report1',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Heart MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage, xRayImage]
                    },
                    {
                        dataId: 'child2',
                        name: 'ultrasound scan',
                        value: '02.25',
                        image: [xRayImage]
                    },
                ]
            },
            {
                icon: DescriptionOutlinedIcon,
                reportId: 'report2',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Heart MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage]
                    },
                ]
            }
        ]
    },
    {
        anatomyType: 'brain' ,
        name: 'Dr. Franki', 
        type: 'head physician', 
        doctorIcon: doctorImage04, 
        reportTitle: 'purpose', 
        reportDescription: 'i propose to prescribe treatment with binimentinib for prescribed weeks until complete recovery.', 
        justifyDirection: 'right',
        reports: [
            {
                icon: TollOutlinedIcon,
                reportId: 'report1',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Brain MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage, xRayImage]
                    },
                    {
                        dataId: 'child2',
                        name: 'ultrasound scan',
                        value: '02.25',
                        image: [xRayImage]
                    },
                ]
            },
        ]
    },
    {
        anatomyType: 'brain' ,
        name: 'Dr. Franki', 
        type: 'head physician', 
        doctorIcon: doctorImage04, 
        reportTitle: 'purpose', 
        reportDescription: 'i propose to prescribe treatment with binimentinib for prescribed weeks until complete recovery.', 
        justifyDirection: 'right',
        reports: [
            {
                icon: TollOutlinedIcon,
                reportId: 'report1',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Brain MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage, xRayImage]
                    },
                    {
                        dataId: 'child2',
                        name: 'ultrasound scan',
                        value: '02.25',
                        image: [xRayImage]
                    },
                ]
            },
            {
                icon: TollOutlinedIcon,
                reportId: 'report2',
                data: [
                    {
                        dataId: 'child1',
                        name: 'ultrasound scan',
                        value: '02.25',
                        image: [xRayImage]
                    },
                ]
            },
        ]
    },
    {
        anatomyType: 'brain' ,
        name: 'Dr. Franki', 
        type: 'head physician', 
        doctorIcon: doctorImage04, 
        reportTitle: 'purpose', 
        reportDescription: 'i propose to prescribe treatment with binimentinib for prescribed weeks until complete recovery.', 
        justifyDirection: 'right',
        reports: [
            {
                icon: TollOutlinedIcon,
                reportId: 'report1',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Brain MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage, xRayImage]
                    },
                    {
                        dataId: 'child2',
                        name: 'ultrasound scan',
                        value: '02.25',
                        image: [xRayImage]
                    },
                ]
            },
        ]
    },
    {
        anatomyType: 'knee' ,
        name: 'Dr. Maccami', 
        type: 'cardilogist', 
        doctorIcon: doctorImage01, 
        reportTitle: 'purpose', 
        reportDescription: 'i propose to prescribe treatment with binimentinib for prescribed weeks until complete recovery.', 
        justifyDirection: 'right',
        reports: [
            {
                icon: TollOutlinedIcon,
                reportId: 'report1',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Knee MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage, xRayImage]
                    },
                ]
            },
        ]
    },
    {
        anatomyType: 'stomach' ,
        name: 'Dr. Maccami', 
        type: 'cardilogist', 
        doctorIcon: doctorImage01, 
        reportTitle: 'purpose', 
        reportDescription: 'i propose to prescribe treatment with binimentinib for prescribed weeks until complete recovery.', 
        justifyDirection: 'right',
        reports: [
            {
                icon: TollOutlinedIcon,
                reportId: 'report1',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Stomach MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage, xRayImage]
                    },
                ]
            },
            {
                icon: DescriptionOutlinedIcon,
                reportId: 'report2',
                data: [
                    {
                        dataId: 'child1',
                        name: 'Stomach MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage]
                    },
                ]
            }
        ]
    },
    {
        anatomyType: 'arm' ,
        name: 'Dr. Maccami', 
        type: 'cardilogist', 
        doctorIcon: doctorImage01, 
        reportTitle: 'purpose', 
        reportDescription: 'i propose to prescribe treatment with binimentinib for prescribed weeks until complete recovery.', 
        justifyDirection: 'right',
        reports: [
            {
                icon: TollOutlinedIcon,
                reportId: 'report1',
                data: [
                    {
                        dataId: 'child1',
                        name: 'arm MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage, xRayImage]
                    },
                ]
            },
            {
                icon: DescriptionOutlinedIcon,
                reportId: 'report2',
                data: [
                    {
                        dataId: 'child1',
                        name: 'arm MRI',
                        value: '03.01',
                        image: [xRayImage, xRayImage, xRayImage]
                    },
                ]
            }
        ]
    }
]

const CommunicationContentMain = () => {
    const archerContainerRef = useRef();
    const ref = useRef(null);
    const activeAnatomy = useSelector((state) => state.activePatientAnatomySlice.activePatientAnatomy); // Getting patientActiveAnatomy value from the store
    const [quickNotesState, setQuickNotesState] = useState(false);
    const [allActiveRelations, setAllActiveRelations] = useState([]);

    const getRelations = () => {
        if (activeAnatomy) {
            return doctorReportExamsData.filter((item) => item.anatomyType === activeAnatomy).map((item, i) => {
                return {
                    targetId: `${item.anatomyType}${i}`,
                    targetAnchor: `${i % 2 === 0 ? 'right' : 'left'}`,
                    sourceAnchor: 'top',
                }
            })
        } else {
            return ''
        }
    }

    const handleRefresh = () => {
        if (archerContainerRef.current) {
            archerContainerRef.current.refreshScreen(); // Manually trigger a refresh
        }
    };

    useEffect(() => {
        setAllActiveRelations(getRelations());
    }, [])

    const dismissedDiv = (divId) => {
        const newRelations = allActiveRelations.filter((item) => item.targetId !== divId);
        setAllActiveRelations(newRelations);
    }

    useEffect(() => {
        setAllActiveRelations(getRelations())
    }, [activeAnatomy])

    return (
        <div className='relative w-full min-h-0 h-full max-h-full flex flex-col gap-4 justify-start items-start py-4 px-4'>
            <ArcherContainer ref={archerContainerRef} className='w-full h-full' svgContainerStyle={{ zIndex: '9' }} strokeColor="#ececec" strokeWidth={1.2} endMarker={false}>
                <div className='relative w-full h-full flex justify-center items-center'>
                    <div className='w-[34%] h-full max-h-full flex justify-center items-center'>
                        <PatientAnatomyWrapper 
                            ref={ref} 
                            setDefaultExpand={false}
                            relations = {allActiveRelations}
                        />
                    </div>
                    { activeAnatomy ? 
                        doctorReportExamsData.filter((item) => item.anatomyType === activeAnatomy).map((item, i) => (
                            <DoctorTestExams 
                                ref={ref} 
                                key={`${item.name}${i}`}
                                anatomyType = {item.anatomyType}
                                targetId = {`${item.anatomyType}${i}`}
                                name = {item.name}
                                type = {item.type}
                                doctorIcon = {item.doctorIcon}
                                reportTitle = {item.reportTitle}
                                reportDescription = {item.reportDescription}
                                xPos = {i%2 === 0 ? `${0+(i*5)}%` : `${i <= 1 ? 100-(20*i) : 100-(5+(i*10))}%`}
                                yPos = {`${i*2}%`}
                                justifyDirection = {i%2 === 0 ? 'start' : 'end'}
                                reports = {item.reports}
                                parentWidth = {'w-[20%]'}
                                parentheight = {'h-max'}
                                flexGap = {'gap-2'}
                                parentBgColor = {'bg-[#ececec]'}
                                roundedSize = {'rounded-[30px]'}
                                setQuickNotesState = {() => {setQuickNotesState(false)}}
                                dismissDivCallback = {(divId) => {dismissedDiv(divId)}}
                                handleRefresh = {() => {handleRefresh()}}
                            />
                        ))
                        :
                        ''
                    }
                    <DoctorDiscussionQuickNotes quickNotesState={quickNotesState} toggleQuickNotesState={() => {setQuickNotesState(!quickNotesState)}} />
                </div>
            </ArcherContainer>
        </div>
    )
}

export default CommunicationContentMain
