import React from 'react'
import ContentWrapper from '../components/ContentWrapper'
import PatientsSidebar from '../components/patientComponents/PatientsSidebar'

const Main = () => {
  return (
    <div className='h-full w-full pt-4 pl-4 flex justify-start items-start'>
      <PatientsSidebar />
      <ContentWrapper />
    </div>
  )
}

export default Main
