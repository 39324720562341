import React, { useEffect } from 'react'
import TabsComponent from '../TabsComponent'
import { useDispatch, useSelector } from 'react-redux';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import HvacOutlinedIcon from '@mui/icons-material/HvacOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { changeDocsTabState } from '../../slices/docsTabsSlice';

const menuArray = [
    {name: 'MRI', icon: HvacOutlinedIcon},
    {name: 'cardiology', icon: DescriptionOutlinedIcon},
    {name: 'metabolic', icon: ScienceOutlinedIcon},
    {name: 'neurology', icon: DescriptionOutlinedIcon},
]

const DocsTabBar = () => {
    const docsTabState = useSelector((state) => state.docsTabsSlice.docsTabState ); // Getting navstate value from the store
	const docsTabStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux

    const updateData = (item) => {
        // Dispatch an action to update the state with the value of nav bar list item which is being clicked
        docsTabStateDispatch(changeDocsTabState(item));
        const element = document.getElementById(`docsTab${item.toLowerCase()}`);
        const area = element.getBoundingClientRect();
        const activeTabIndicator = document.getElementById('activeDocsTabIndicator');
        if (activeTabIndicator) {
            activeTabIndicator.style.position="fixed";
            activeTabIndicator.style.top = `${area.top}px`;
            activeTabIndicator.style.left = `${area.left}px`;
            activeTabIndicator.style.height = `${area.height}px`;
            activeTabIndicator.style.width = `${area.width}px`;
            activeTabIndicator.style.transition = "all 0.5s"
        }
    };

    useEffect(() => {
        setTimeout(() => {
            updateData(docsTabState.toLowerCase());
        }, 500);
    }, []);
    
    return (
        <div className='w-full h-max flex justify-start items-center pr-4 gap-1'>
            <ButtonWithCenterIcon parentWidth={'w-max'} icon={CottageOutlinedIcon} />
            <TabsComponent 
                menuArray={menuArray} 
                tabState={docsTabState} 
                onClickListener={updateData} 
                tabItemIdPrefix={'docsTab'} 
                tabBgColor={'#eee'} 
                activeTabFontColor={'#ccc'}
            />
            <ButtonWithCenterIcon 
                parentWidth={'w-auto'} 
                parentHeight={'h-auto'} 
                icon={AddRoundedIcon} 
                iconFontSize={16} 
                bgColor={'bg-[#F3FF47]'}
                customParentClasses='flex items-center'
            />
            <div id='activeDocsTabIndicator' className={`absolute z-[0] bg-[#272d2d] rounded-[100px] transition duration-[0.3s]`}></div>
        </div>
    )
}

export default DocsTabBar
