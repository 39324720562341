import React from 'react'

const AccordionHeaderComponent = ({ bg_color, Icon, text, fontColor }) => {
  return (
    <div className={`rounded-full px-2.5 py-2  leading-none`}
      style={{ backgroundColor: `${bg_color ? bg_color : ''}`, color: `${fontColor ? fontColor : ''}` }}
    >
        {Icon ? <Icon style={{ fontSize: '16px' }} /> : ''}
        {text ? <p className=''>{text}</p> : ''}
    </div>
  )
}

export default AccordionHeaderComponent
