import React, { useState } from 'react'
import image from '../../assets/images/doctorImage1-removebg-preview.png'
import xRayImage from '../../assets/images/Xray_share.jpeg';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import DoctorProfileBadge from '../doctorComponents/DoctorProfileBadge';
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import CustomChips from '../CustomChips';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CustomInfoAccordion from './CustomInfoAccordion';
import AttractionsOutlinedIcon from '@mui/icons-material/AttractionsOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import AnimationOutlinedIcon from '@mui/icons-material/AnimationOutlined';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import DoctorExams from '../reusableComponents/DoctorExams';
import { CircleIcon } from '../../assets/icons/MedicalIcons';
import CustomTimeline from '../reusableComponents/CustomTimeline';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
import { TextField } from '@mui/material';
import { getCurrentDateString } from '../../utils/getCurrentDateString';

const appointmentExamsDoctorsData = [
    {image: image, name: 'Dr. heart', type: 'therapist', exams: [
        {icon: LensBlurIcon, text: '', status: '' }, 
        {icon: ScienceOutlinedIcon, text: '', status: ''},
    ]},
    {image: image, name: 'Dr rousey', type: 'cardiologist', exams: [
        {icon: LensBlurIcon, text: '', status: ''}, 
        {icon: ScienceOutlinedIcon, text: 'bypassing', status: 'on recovery period'}, 
        {icon: AnimationOutlinedIcon, text: '', status: ''}
    ]},
    {image: image, name: 'Dr james', type: 'Surgeon', exams: [
        {icon: ScienceOutlinedIcon, text: 'bypassing', status: 'on recovery period'}
    ]}
]

const maxSeverityData = [
    {date: getCurrentDateString(), amt: 60, data : [ {type: 'labs', label: 'Blunt Pain', icon: <CircleIcon width={6} height={6} fill={'#fff'} />} ]},
]

const currentSeverityData = [
    {date: getCurrentDateString(), amt: 60, data : [ {type: 'labs', label: 'Blunt Pain', icon: <CircleIcon width={6} height={6} fill={'#fff'} />} ]},
]

const calculatePosition = (date) => {
    const [day, month] = date.split('/');
    const fractionalValue = (parseInt(day) - 1) / 30; // Assuming 30 days in a month
    return parseInt(month - 1) + fractionalValue;
};

const adjustedMaxSeverityData = maxSeverityData.map(item => ({
    ...item,
    position: calculatePosition(item.date)
}));

const adjustedCurrentSeverityData = currentSeverityData.map(item => ({
    ...item,
    position: calculatePosition(item.date)
}));


const AddComplaintForm = () => {
    const [complaintName, setComplaintName] = useState('');
    const [openDetails, setOpenDetails] = useState(false);
    const monthsInYear = Array.from({ length: 13 }, (_, i) => i); // Array of numbers from 1 to 12

    const onInit = () => {};

    return (
        <div className='relative w-full h-full flex flex-col justify-start items-start gap-2'>
            <div className='h-1/4 w-full flex justify-start items-start gap-4 pb-2 pr-2'>
                <div className='relative w-full px-10 h-full bg-[#f4f4f4] rounded-[30px] flex flex-col justify-center items-start'>
                    <p className='text-[12px]'>12.12.2023</p>
                    <TextField 
                        id="standard-basic" 
                        label="Add New Complaint" 
                        variant="standard" 
                        value={complaintName} 
                        onChange={(e) => {setComplaintName(e.target.value)}}
                    />
                    {/* <input 
                        type='text' 
                        className='text-[22px] whitespace-normal' 
                        placeholder='Add New Complaint' 
                        value={complaintName} 
                        onChange={(e) => {setComplaintName(e.target.value)}}
                    /> */}
                    <div className='absolute top-0 right-8 flex gap-2 '>
                        <div className='rounded-br-[20px] bg-[#fff] rounded-bl-[20px] p-3 px-5'>
                            <p className='text-[12px]'>Manual</p>
                        </div>
                        <div className='rounded-br-[20px] bg-[#fff] rounded-bl-[20px] p-3 px-5'>
                            <p className='text-[12px]'>HPI</p>
                        </div>
                    </div>
                </div>
                <div className='w-full h-full flex flex-col justify-center items-start gap-2'>
                    <div className='w-full flex justify-start items-start gap-1'>
                        <div className='w-max rounded-[20px] bg-[#f4f4f4] text-[14px] shadow-sm'>
                            <DoctorProfileBadge 
                                image={image} 
                                altText={'dr. peterson'} 
                                name={'dr. peterson'} 
                                subtitleText={'Cardiologist'} 
                                bgColor={'transparent'}
                            />
                        </div>
                        <ButtonWithCenterIcon customButtonClasses={'shadow-sm'} parentWidth={'w-max'} icon={PhoneOutlinedIcon} bgColor={'bg-[#f4f4f4]'} />
                        <ButtonWithCenterIcon customButtonClasses={'shadow-sm'} parentWidth={'w-max'} icon={TextsmsOutlinedIcon} bgColor={'bg-[#f4f4f4]'} />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p>docs</p>
                            <p>03</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p>meds</p>
                            <p>02</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p>labs</p>
                            <p>01</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p>operations</p>
                            <p>01</p>
                        </CustomChips>
                    </div>
                </div>
            </div>
            <div className='h-1/4 w-full bg-[#f0f0f0] flex justify-start items-start gap-4 px-3 py-2'>
                <div className='w-full h-full flex flex-col gap-[2px] '>
                    <p className='capitalize text-[12px] font-medium mb-1'>Chief Complaint</p>
                    <div className='flex gap-2 items-center flex-wrap'>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#272d2d]'} textColor={'text-[#fff]'}>
                            <p className='capitalize'>vertigo</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>nosebleed</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#272d2d]'} textColor={'text-[#fff]'}>
                            <p className='capitalize'>chest pain</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>discomfort</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>cough</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#272d2d]'} textColor={'text-[#fff]'}>
                            <p className='capitalize'>nausea</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>swelling</p>
                        </CustomChips>
                    </div>
                </div>
                <div className='w-full h-full flex flex-col gap-[2px] '>
                    <div className='w-full flex justify-between items-center'>
                        <p className='capitalize text-[12px] font-medium mb-1'>Details</p>
                        <ButtonWithCenterIcon parentWidth={'w-max'} width={'w-[24px]'} height={'h-[24px]'} icon={AddRoundedIcon} iconFontSize={14} />
                    </div>
                    <div className='w-full flex-1 min-h-0 flex justify-center items-start gap-2'>
                        <div className='w-7/12 h-full relative'>
                            <CustomInfoAccordion 
                                title="" 
                                onClick={() => {setOpenDetails(!openDetails)}} 
                                isopen={openDetails} 
                                parentContainerClasses={'absolute top-0 left-0'}
                                staticHeaderComponent={true}
                                showCloseButton={true}
                                buttonClickHandler={() => {setOpenDetails(!openDetails)}} 
                                headerComponent={
                                    <DoctorProfileBadge 
                                        image={image} 
                                        altText={'dr. peterson'} 
                                        name={'dr. peterson'} 
                                        subtitleText={'Cardiologist'} 
                                        bgColor={'transparent'}
                                        flexDirection={'flex-row-reverse'}
                                        parentPadding={'px-0'}
                                    />
                                } 
                            >
                                <div className='w-full flex flex-col justify-start items-start !text-[12px] px-3 py-2'>
                                    <div className='w-full flex justify-between items-center'>
                                        <p className='text-[#000] !text-[14px] capitalize'>description</p>
                                        <p className='text-[#666]'>03.01</p>
                                    </div>
                                    <div className='w-full flex flex-wrap justify-between items-center'>
                                        <p className='text-[#000] capitalize'>
                                            patient with migraines shows worsening symptoms. headaches and dizziness. i recommend physical therapy and MRI 
                                            analysis to improve blood circulation and relieve tension in the head area. i am issuing a referral and 
                                            scheduling a follow-up appointment.
                                        </p>
                                    </div>
                                </div>
                            </CustomInfoAccordion>
                        </div>
                        <div className='w-5/12 h-full flex flex-col items-start justify-between rounded-[30px] bg-[#f6f6f6] p-1'>
                            <div className='w-full flex justify-start items-center gap-2'>
                                <ButtonWithCenterIcon 
                                    parentWidth={'w-max'} 
                                    width={'w-[34px]'} 
                                    height={'h-[34px]'} 
                                    icon={AttractionsOutlinedIcon} 
                                    iconFontSize={14} 
                                    iconColor={'#fff'}
                                    bgColor={'bg-[#272d2d]'}
                                />
                                <div className='w-full flex flex-col justify-center items-start'>
                                    <p className='font-light text-[10px] p-0 m-0 leading-none capitalize'>01.01</p>
                                    <p className='capitalize text-[12px] p-0 m-0 leading-none whitespace-nowrap'>x-ray</p>
                                </div>
                            </div>
                            <div className='h-1/2 w-full rounded-[30px] overflow-hidden flex items-center justify-center'>
                                <LightGallery
                                    onInit={onInit}
                                    elementClassNames=''
                                    speed={200}
                                    plugins={[lgThumbnail, lgZoom]}
                                >
                                    <a href={xRayImage} data-src={xRayImage}>
                                        <img className='relative w-full h-full object-cover object-center' src={xRayImage} alt="x-ray" />
                                    </a>
                                </LightGallery>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-1/4 w-full bg-[#f0f0f0] flex justify-start items-start gap-4 px-3 py-2'>
                <div className='w-full h-full flex flex-col gap-[2px] '>
                    <div className='w-full flex justify-between items-center'>
                        <p className='capitalize text-[12px] font-medium mb-1'>appointment exams</p>
                        <ButtonWithCenterIcon parentWidth={'w-max'} width={'w-[24px]'} height={'h-[24px]'} icon={AddRoundedIcon} iconFontSize={14} />
                    </div>
                    <div className='flex h-full gap-2 items-start flex-wrap'>
                        {
                            appointmentExamsDoctorsData.map((item, i) => (
                                <DoctorExams key={`${item.name}${i}`} image={item.image} name={item.name} type={item.type} exams={item.exams} />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='h-1/4 w-full bg-[#f0f0f0] flex justify-start items-start gap-4 px-3 py-2 pb-4'>
                <div className='w-full h-full flex flex-col gap-[2px] '>
                    <p className='capitalize text-[12px] font-medium mb-1'>max severity</p>
                    <div className='flex gap-2 items-center flex-wrap'>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>mild</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>moderate</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#272d2d]'} textColor={'text-[#fff]'}>
                            <p className='capitalize'>severe</p>
                        </CustomChips>
                    </div>
                    <CustomTimeline
                        parentDivHeight={'h-full'}
                        graphData={adjustedMaxSeverityData}
                        ticks={monthsInYear}
                        graphMargins = {{right: 20, top: 0, bottom: 0, left: 10}}
                        customIdentifier={'maxSeverity'}
                        showAxisLine={true}
                        showTicks={true}
                        showTickLine={true}
                    />
                </div>
                <div className='w-full h-full flex flex-col gap-[2px] '>
                    <div className='w-full flex justify-between items-center'>
                        <p className='capitalize text-[12px] font-medium mb-1'>current severity</p>
                        <ButtonWithCenterIcon parentWidth={'w-max'} width={'w-[24px]'} height={'h-[24px]'} icon={AddRoundedIcon} iconFontSize={14} />
                    </div>
                    <div className='flex gap-2 items-center flex-wrap'>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#272d2d]'} textColor={'text-[#fff]'}>
                            <p className='capitalize'>mild</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>moderate</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>severe</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p className='capitalize'>gone</p>
                        </CustomChips>
                    </div>
                    <CustomTimeline
                        parentDivHeight={'h-full'}
                        graphData={adjustedCurrentSeverityData}
                        ticks={monthsInYear}
                        graphMargins = {{right: 20, top: 0, bottom: 0, left: 10}}
                        customIdentifier={'maxSeverity'}
                        showAxisLine={true}
                        showTicks={true}
                        showTickLine={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default AddComplaintForm
