import React, { Fragment } from 'react'
import styled from 'styled-components';

const MenuListItem = styled.li`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 100px;
    background-color: transparent;

    /* Styles for ::before pseudo-element */
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        z-index: 0;
        background-color: ${({ $tabBgColor }) => ( $tabBgColor ? $tabBgColor : 'transparent')};
    }
`;

const MenuListText = styled.span`
    position: relative;
    display: inline;
    font-size: 12px;
    text-transform: capitalize;
    line-height: 1;
    z-index: 999;
    color: ${({ $activeTabFontColor }) => ( $activeTabFontColor ? $activeTabFontColor : 'initial')};
`;

const CustomIcon = ({ icon: Icon, fontSize, color }) => {
    return <Icon className='z-[999] transition-all duration-1000' style={{ fontSize, color }} />;
};

const TabsComponent = ({ menuArray, tabState, onClickListener, tabItemIdPrefix, tabBgColor, activeTabFontColor }) => {
  return (
    <Fragment>
        {
          menuArray.map((item, i) => {
              return (
                <Fragment key={i}>
                  <MenuListItem 
                    $tabBgColor={tabBgColor} 
                    id={`${tabItemIdPrefix}${item.name.toLowerCase()}`} 
                    className={`${tabState.toLowerCase() === item.name.toLowerCase() ? 'active' : ''}`} 
                    onClick={() => onClickListener(item.name.toLowerCase())}
                  >
                    {item.icon ? <CustomIcon key={i} icon={item.icon} fontSize="14px" color={`${tabState.toLowerCase() === item.name.toLowerCase() ? activeTabFontColor ? activeTabFontColor : '' : ''}`} /> : ''}
                    {item.name ? <MenuListText $activeTabFontColor={tabState.toLowerCase() === item.name.toLowerCase() ? activeTabFontColor : ''} >{item.name}</MenuListText> : ''}
                  </MenuListItem>
                </Fragment>
              )
          })
        }
    </Fragment>
  )
}

export default TabsComponent
