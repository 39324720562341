import React from 'react'
import CustomInfoAccordion from './CustomInfoAccordion'
import AccordionHeaderComponent from '../AccordionHeaderComponent'

const HeaderComponent = () => {
    return (
        <div className='flex items-center justify-center'>
            <AccordionHeaderComponent bg_color={'#272D2D'} text={98} fontColor={'#fff'} />
            <AccordionHeaderComponent bg_color={'#fff'} text={'36,7'} />
        </div>
    )
}

const PatientVitals = ({ onClick, isOpen }) => {
  return (
    <CustomInfoAccordion title="Vitals" onClick={onClick} isopen={isOpen} headerComponent={<HeaderComponent />} >
        <div className='w-full flex flex-col justify-start items-start !text-[12px]'>
            <div className='px-4 py-2'>
                <p className='text-[#666]'>Heart beat</p>
                <p className='text-[#000] !text-[14px]'>98</p>
            </div>
            <div className='w-full flex flex-wrap justify-between items-center'>
                <div className='w-1/2 bg-[#f9f9f9] rounded-br-[30px]'>
                    <div className='pl-4 py-4'>
                        <p className='text-[#666] capitalize'>pressure</p>
                        <p className='text-[#000] !text-[14px]'>120/60</p>
                    </div>
                </div>
                <div className='w-1/2 bg-[#f9f9f9] rounded-bl-[30px]'>
                    <div className='pl-4 py-4'>
                        <p className='text-[#666] capitalize'>temperature</p>
                        <p className='text-[#000] !text-[14px]'>37.7</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-wrap justify-between items-center'>
                <div className='w-1/2 bg-[#f9f9f9] rounded-tr-[30px]'>
                    <div className='pl-4 py-4'>
                        <p className='text-[#666]'>oxygen</p>
                        <p className='text-[#000] !text-[14px]'>98</p>
                    </div>
                </div>
                <div className='w-1/2 bg-[#f9f9f9] rounded-tl-[30px]'>
                    <div className='pl-4 py-4'>
                        <p className='text-[#666]'>Raspiratory</p>
                        <p className='text-[#000] !text-[14px]'>24</p>
                    </div>
                </div>
            </div>
        </div>
    </CustomInfoAccordion>
  )
}

export default PatientVitals
