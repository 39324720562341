import React, { useState } from 'react'
import loginPage from '../assets/images/womanDoctorLoginPage.jpeg';
import quantisLogo from '../assets/images/quantisLogo.png';
import QrCodeImage from '../assets/images/QRcode.png';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { login } from '../slices/authThunk';
import { useDispatch } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const Login = () => {
    const [username, setUsername] = useState('');
    const [userPass, setUserPass] = useState('');
    const dispatch = useDispatch(); // Get the Redux dispatch function
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordVisibilityToggle = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = () => {
        dispatch(login(username, userPass)); // Dispatch the login thunk
    };

    return (
        <div className='w-full bg-[#fff] mx-auto h-full flex justify-center items-center gap-4 p-5 overflow-hidden'>
            <div className='relative w-[620px] h-full rounded-[20px] overflow-hidden shadow-[0_0_20px_rgba(0,0,0,0.05)]'>
                <div className='absolute top-10 left-8 z-[999]'>
                    <p className='text-[#fff] font-normal text-[30px]'>For those <br /> who care</p>
                </div>
                <img className='relative block w-full h-full object-cover object-center' alt='woman-doctor' src={loginPage} />
            </div>
            <div className='w-[620px] h-full flex flex-col justify-start items-start gap-4'>
                <div 
                    className='
                        w-full h-1/2 border-[1px] border-[#ddd] rounded-[20px] bg-[#fff] p-8 
                        shadow-[0_0_20px_rgba(0,0,0,0.05)] flex flex-col justify-between items-center
                    '
                >
                    <div className='w-[50px] h-[50px] overflow-hidden flex justify-center items-center'>
                        <img className='w-full h-full relative block' src={quantisLogo} alt='' />
                    </div>
                    <div className='flex flex-col justify-start items-center gap-2'>
                        <p className='text-[#272d2d] font-bold text-[16px]'>Confirm account</p>
                        <p className='text-[#999] text-[12px]'>Access code was sent to your inbox:</p>
                        <div className='flex gap-4 justify-center items-center'>
                            <div className='flex gap-2 justify-center items-center'>
                                <TextField 
                                    size="small" 
                                    sx={{ 
                                        width: '26px',
                                        '.MuiInputBase-root': { borderRadius: '5px' },
                                        '.MuiInputBase-input': { fontSize: '12px', padding: '4px 10px' }, 
                                        '.MuiInputBase-root::before': { display: 'none' }, 
                                        '.MuiInputBase-root::after': { display: 'none' } 
                                    }} 
                                    inputProps={{maxLength: 1}}
                                    placeholder='' 
                                    variant="filled" 
                                    onChange={(e) => {}}
                                />
                                <TextField 
                                    size="small" 
                                    sx={{ 
                                        width: '26px',
                                        '.MuiInputBase-root': { borderRadius: '5px' },
                                        '.MuiInputBase-input': { fontSize: '12px', padding: '4px 10px' }, 
                                        '.MuiInputBase-root::before': { display: 'none' }, 
                                        '.MuiInputBase-root::after': { display: 'none' } 
                                    }} 
                                    inputProps={{maxLength: 1}}
                                    placeholder='' 
                                    variant="filled" 
                                    onChange={(e) => {}}
                                />
                                <TextField 
                                    size="small" 
                                    sx={{ 
                                        width: '26px',
                                        '.MuiInputBase-root': { borderRadius: '5px' },
                                        '.MuiInputBase-input': { fontSize: '12px', padding: '4px 10px' }, 
                                        '.MuiInputBase-root::before': { display: 'none' }, 
                                        '.MuiInputBase-root::after': { display: 'none' } 
                                    }} 
                                    inputProps={{maxLength: 1}}
                                    placeholder='' 
                                    variant="filled" 
                                    onChange={(e) => {}}
                                />
                            </div>
                            <div className='flex gap-2 justify-center items-center'>
                                <TextField 
                                    size="small" 
                                    sx={{ 
                                        width: '26px',
                                        '.MuiInputBase-root': { borderRadius: '5px' },
                                        '.MuiInputBase-input': { fontSize: '12px', padding: '4px 10px' }, 
                                        '.MuiInputBase-root::before': { display: 'none' }, 
                                        '.MuiInputBase-root::after': { display: 'none' } 
                                    }} 
                                    inputProps={{maxLength: 1}}
                                    placeholder='' 
                                    variant="filled" 
                                    onChange={(e) => {}}
                                />
                                <TextField 
                                    size="small" 
                                    sx={{ 
                                        width: '26px',
                                        '.MuiInputBase-root': { borderRadius: '5px' },
                                        '.MuiInputBase-input': { fontSize: '12px', padding: '4px 10px' }, 
                                        '.MuiInputBase-root::before': { display: 'none' }, 
                                        '.MuiInputBase-root::after': { display: 'none' } 
                                    }} 
                                    inputProps={{maxLength: 1}}
                                    placeholder='' 
                                    variant="filled" 
                                    onChange={(e) => {}}
                                />
                                <TextField 
                                    size="small" 
                                    sx={{ 
                                        width: '26px',
                                        '.MuiInputBase-root': { borderRadius: '5px' },
                                        '.MuiInputBase-input': { fontSize: '12px', padding: '4px 10px' }, 
                                        '.MuiInputBase-root::before': { display: 'none' }, 
                                        '.MuiInputBase-root::after': { display: 'none' } 
                                    }} 
                                    inputProps={{maxLength: 1}}
                                    placeholder='' 
                                    variant="filled" 
                                    onChange={(e) => {}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div className='h-[64px] w-[64px] flex justify-center items-center'>
                            <img className={`relative block h-full w-full cursor-pointer`} src={QrCodeImage} alt='QR-code' onClick={() => {}}/>
                        </div>
                        <p className='text-[#dd6f11] text-[12px]'>Scan to confirm on mobile</p>
                    </div>
                </div>
                <div 
                    className='
                        w-full h-1/2 border-[1px] border-[#ddd] rounded-[20px] bg-[#fff] p-5 
                        shadow-[0_0_20px_rgba(0,0,0,0.05)] flex flex-col justify-between items-center
                    '
                >
                    <div className='w-[50px] h-[50px] overflow-hidden flex justify-center items-center'>
                        <img className='w-full h-full relative block' src={quantisLogo} alt='' />
                    </div>
                    <div className='flex flex-col justify-start items-center'>
                        <p className='text-[#272d2d] font-bold text-[16px] '>Let's get started</p>
                        <p className='text-[#888] text-[10px] text-center'>
                            We need some basic info about you to set your profile up.
                            <br />
                            You will be able to edit it later.
                        </p>
                    </div>
                    <div className='flex flex-col items-center justify-center gap-2'>
                        <div className='relative w-[50px] h-[50px] rounded-full flex justify-center items-center bg-[#eee] p-4'>
                            <PermIdentityOutlinedIcon sx={{ fontSize: '24px', fontWeight: '100', cursor: 'pointer' }} />
                            <div className='absolute -bottom-[6px] left-[50%] -translate-x-[50%] bg-gradient-to-b from-blue-400 to-blue-700 rounded-full flex justify-center items-center'>
                                <AddRoundedIcon sx={{ fontSize: '14px', color: '#fff', cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <p className='font-medium leading-[1.3] text-[12px] m-0 p-0'>Samantha Smith</p>
                            <p className='font-medium leading-[1.3] text-[12px] text-[#999] p-0 m-0'>Cardiologist</p>
                        </div>
                    </div>
                    <div className='w-[60%] flex flex-col justify-between items-center gap-3'>
                        <div className='w-full flex flex-col items-center justify-center gap-1'>
                            <TextField 
                                size="small" 
                                sx={{ 
                                    width: '100%', 
                                    '.MuiInputBase-root': { borderRadius: '5px' },
                                    '.MuiInputBase-input': { fontSize: '12px', padding: '5px 10px' }, 
                                    '.MuiInputBase-root::before': { display: 'none' }, 
                                    '.MuiInputBase-root::after': { display: 'none' } 
                                }} 
                                placeholder='Enter Username' 
                                variant="filled" 
                                value={username}
                                onChange={(e) => {setUsername(e.target.value)}}
                            />
                            <TextField 
                                size="small" 
                                sx={{ 
                                    width: '100%', 
                                    '.MuiInputBase-root': { borderRadius: '5px' },
                                    '.MuiInputBase-input': { fontSize: '12px', padding: '5px 10px' }, 
                                    '.MuiInputBase-root::before': { display: 'none' }, 
                                    '.MuiInputBase-root::after': { display: 'none' } 
                                }} 
                                type = {showPassword ? 'text' : 'password'}  
                                placeholder='Enter Password' 
                                variant="filled" 
                                value={userPass}
                                onChange={(e) => {setUserPass(e.target.value)}}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handlePasswordVisibilityToggle}
                                          edge="end"
                                        >
                                            {showPassword ? 
                                                <VisibilityOutlinedIcon sx={{ fontSize: '15px' }} /> 
                                                : 
                                                <VisibilityOffOutlinedIcon sx={{ fontSize: '15px' }} />
                                            }
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <Button 
                            className='!text-[12px] w-max bg-gradient-to-b from-blue-400 to-blue-700' 
                            variant="contained" 
                            disableElevation
                            size="small"
                            endIcon={<EastRoundedIcon fontSize={'10px'} />}
                            sx={{ textTransform: 'capitalize', padding: '2px 10px', fontWeight: '500' }}
                            onClick={() => {handleLogin()}}
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
