import React, { useCallback, useEffect, useRef, forwardRef } from 'react'
import ButtonWithCenterIcon from './ButtonWithCenterIcon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSelector } from 'react-redux';
import { ArcherElement } from 'react-archer';

const CustomDrawer = forwardRef(({ isOpen, toggleDrawer, renderArrow, children }, ref) => {
    const navState = useSelector((state) => state.navSlice.navState); // Getting navstate value from the store
    const drawerRef = useRef(null);
    
    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Escape' && isOpen) {
            toggleDrawer(false, navState);
        }
    }, [isOpen, navState, toggleDrawer]);

    // const handleMouseDown = useCallback((event) => {
    //     if (isOpen && drawerRef.current && !drawerRef.current.contains(event.target)) {
    //          toggleDrawer(false, navState);
    //     }
    // }, [isOpen, toggleDrawer]);

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleMouseDown);
        
    //     return () => {
    //         document.removeEventListener('mousedown', handleMouseDown);
    //     };
    // }, [handleMouseDown]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        
        return () => {
            document.addEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);
    
  return (
    <div ref={drawerRef} className={`bg-gradient-to-b from-[#DFDFD7] to-[#E4E4DC] h-full 
        absolute z-[999] top-0 right-0 -bottom-[5%] transition-all duration-500
        ${isOpen ? 'left-[0]' : 'left-[100%]'}
    `}>
        <div className='bg-[#ececec] w-full h-full rounded-s-[30px] rounded-tl-[40px] shadow-sm overflow-hidden'>
            {renderArrow ? 
                <ArcherElement id={'customDrawer'}>
                    <div className='absolute top-0 left-0 z-[999]'>
                        <ButtonWithCenterIcon icon={CloseRoundedIcon} bgColor={'bg-[#f3ff47]'} clickHandler={() => toggleDrawer(false, navState)} />
                    </div>
                </ArcherElement>
                :
                <div className='absolute top-0 left-0 z-[999]'>
                    <ButtonWithCenterIcon icon={CloseRoundedIcon} bgColor={'bg-[#f3ff47]'} clickHandler={() => toggleDrawer(false, navState)} />
                </div>
            }
            { children }
        </div>
    </div>
  )
});

export default CustomDrawer
