import { createSlice } from '@reduxjs/toolkit';

export const formDrawerSlice = createSlice({
	name: 'formDrawerState',
	initialState: {
		formDrawerState: false,
		formDrawerContentType: '',
	},
	reducers: {
		changeFormDrawerState: {
			reducer: (state, action) => {
				console.log(action.payload);
				state.formDrawerState = action.payload.formDrawerState
				state.formDrawerContentType = action.payload.formDrawerContentType
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		},
	}
})

// each case under reducers becomes an action
export const { changeFormDrawerState } = formDrawerSlice.actions

export default formDrawerSlice.reducer