import React, { useEffect } from 'react'
import TabsComponent from '../TabsComponent'
import { useDispatch, useSelector } from 'react-redux';
import { changeOverviewTabState } from '../../slices/overviewTabsSlice';

const menuArray = [
    {icon: '' , name: 'diagnosis'},
    {icon: '' , name: 'timeline'},
    {icon: '' , name: 'visits'},
    {icon: '' , name: 'procedures'},
    {icon: '' , name: 'meds'},
    {icon: '' , name: 'labs'},
    {icon: '' , name: 'imaging'},
]

const OverviewTabBar = () => {
    const overviewTabState = useSelector((state) => state.overviewTabSlice.overviewTabState ); // Getting navstate value from the store
	const overviewTabStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux

    const updateData = (item) => {
        // Dispatch an action to update the state with the value of nav bar list item which is being clicked
        overviewTabStateDispatch(changeOverviewTabState(item));
        const element = document.getElementById(`overviewTab${item.toLowerCase()}`);
        if (element) {
            const area = element.getBoundingClientRect();
            const activeTabIndicator = document.getElementById('activeOverviewTabIndicator');
            if (activeTabIndicator) {
                activeTabIndicator.style.position="fixed";
                activeTabIndicator.style.top = `${area.top}px`;
                activeTabIndicator.style.left = `${area.left}px`;
                activeTabIndicator.style.height = `${area.height}px`;
                activeTabIndicator.style.width = `${area.width}px`;
                activeTabIndicator.style.transition = "all 0.5s"
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            updateData(overviewTabState.toLowerCase());
        }, 500);
    }, [])
    
    return (
        <div className='w-full h-max flex justify-between items-center pr-4'>
            <TabsComponent menuArray={menuArray} tabState={overviewTabState} onClickListener={updateData} tabItemIdPrefix={'overviewTab'} tabBgColor={'#eee'} />
            <div id='activeOverviewTabIndicator' className={`absolute z-[0] bg-[#F3FF47] rounded-[100px] transition duration-[0.3s]`}></div>
        </div>
    )
}

export default OverviewTabBar
