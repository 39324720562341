import React from 'react'; // Importing React
import ReactDOM from 'react-dom/client'; // Importing react DOM to render App.
import './index.css'; //Importing index.css for styles applied and tailwind integration.
import App from './App'; // App is main component where we enter after index which will contain all of our child components/sections.
import { Provider } from 'react-redux'; // provider is used to integrate redux in our application. Its work is to provide store to our components.
import store from './store/store'; // store is basically where all of out state from slices(i.e actions/reducers) is kept.
import reportWebVitals from './reportWebVitals'; // Not in use but Used for testing purpose later in appications.

const root = ReactDOM.createRoot(document.getElementById('root')); // Here we are creating a root using reactDom createRoot function
root.render( // here we're telling our root to render App component
 <Provider store={store}> {/* to provide store to our components to use state from redux store */}
   {/* <React.StrictMode> */}
      <App />
   {/* </React.StrictMode> */}
 </Provider>
);
reportWebVitals();
