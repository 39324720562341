import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../slices/authSlice'; // Import the logout action
import { isTokenExpired } from '../utils/authutils'; // Function to check if token has expired
import Login from '../pages/Login';
import { restoreAuthFromToken } from '../slices/authThunk';

const AuthWrapper = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true); // Loading state to prevent flickering
    const [firstUseEffect, setFirstUseEffect] = useState(false);
    const token = useSelector((state) => state.auth.token); // Get token from Redux state
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state
    const dispatch = useDispatch();

    // Restore authentication on component mount
    useEffect(() => {
        dispatch(restoreAuthFromToken()); // Check and restore token from localStorage
        setFirstUseEffect(true);
        setIsLoading(false); // Set loading to false after restoration
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && firstUseEffect && isTokenExpired(token)) {
            dispatch(logout()); // If token has expired, log out
        }
    }, [token, dispatch, firstUseEffect, isLoading]);

    if (isLoading) {
        return ''
    }

    if (!isAuthenticated) {
        return <Login />; // Redirect or render a login component
    }

    return children; // Render the main content if authenticated
};

export default AuthWrapper;
