import React from 'react'
import TitleSubtitleComponent from './TitleSubtitleComponent'
import ButtonWithCenterIcon from '../ButtonWithCenterIcon'

const VideoConferenceCard = ({ 
    parentheight, 
    parentWidth, 
    parentBgColor, 
    roundedSize, 
    flexGap,
    icon, 
    buttonIcon,
    buttonIconBgColor,
    image, 
    name, 
    type, 
    active,
    iconAltText,
    imageAltText
}) => {
    return (
        <div className={`
            flex flex-col items-center justify-center
            ${parentWidth ? parentWidth : 'w-full'}
            ${parentheight ? parentheight : 'h-full'}
            ${flexGap ? flexGap : ''}
            ${parentBgColor ? parentBgColor : 'bg-transparent'}
            ${roundedSize ? roundedSize : 'rounded-none'}
        `}>
            <div className={`h-1/4 w-full flex justify-between items-center p-1 gap-2`}>
                <div className='rounded-full w-[62px] h-[38px] overflow-hidden bg-[length:80px_50px] bg-[center_top] bg-no-repeat' style={{ backgroundImage : `url(${icon})`}}></div>
                <TitleSubtitleComponent 
                    parentFlexGap = {'gap-[3px]'}
                    parentPadding={'py-2'}
                    titleText={name} 
                    subText={type} 
                    titleFontSize={'text-[12px]'} 
                    subTitleFontSize={'text-[10px]'} 
                    titleFontWeight = {'font-[400]'}
                    subTitleFontWeight = {'font-[400]'}
                    subTitleTextColor = {'text-[#666]'}
                />
                <div className='flex items-center justify-center gap-1'>
                    <ButtonWithCenterIcon 
                        parentWidth={'w-max'} 
                        width={'w-[30px]'} 
                        height={'h-[30px]'} 
                        icon={buttonIcon} 
                        iconFontSize={14} 
                        iconColor={'#666'}
                        bgColor={buttonIconBgColor ? buttonIconBgColor : 'bg-transparent'}
                        clickHandler={() => {}} 
                    />
                </div>
            </div>
            <div className={`h-3/4 flex justify-center items-center rounded-[30px] overflow-hidden`}>
                <img src={image} alt={imageAltText} />
            </div>
        </div>
    )
}

export default VideoConferenceCard
