import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		token: null, // Initially no token
        isAuthenticated: false, // User is not authenticated
	},
	reducers: {
		loginSuccess: (state, action) => {
            state.token = action.payload; // Store the token
            state.isAuthenticated = true; // Set user as authenticated
		},
        logout: (state) => {
            state.token = null; // Clear the token
            state.isAuthenticated = false; // Set user as not authenticated
        },
	}
})

// each case under reducers becomes an action
export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer