import React from 'react'
import image from '../../assets/images/doctorImage1-removebg-preview.png'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import DoctorProfileBadge from './DoctorProfileBadge';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import { performLogout } from '../../slices/authThunk';
import { useDispatch } from 'react-redux';

const DoctorsAccountBar = () => {
  const dispatch = useDispatch(); // To dispatch Redux actions

  const handleLogout = () => {
    dispatch(performLogout()); // Trigger the Redux thunk to log out
  };

  return (
    <div className='text-[12px] rounded-[100px] p-[1px] bg-[#ececec] overflow-hidden flex gap-3 ml-auto'>
      <DoctorProfileBadge image={image} altText={'dr. peterson'} name={'dr. peterson'} subtitleText={'welcome'} />
      <div className='flex justify-center items-center'>
        <ButtonWithCenterIcon icon={NotificationsNoneOutlinedIcon} />
        <ButtonWithCenterIcon icon={SettingsRoundedIcon} />
        <ButtonWithCenterIcon icon={PowerSettingsNewRoundedIcon} clickHandler={() => {handleLogout();}} />
      </div>
    </div>
  )
}

export default DoctorsAccountBar
