import React from 'react'

const ButtonWithCenterIcon = ({ 
  parentWidth, 
  parentHeight, 
  width, 
  height, 
  bgColor, 
  icon: Icon, 
  iconFontSize, 
  iconColor ,
  customParentClasses, 
  customButtonClasses, 
  clickHandler 
}) => {
  return (
    <div className={`
        ${parentWidth ? parentWidth : 'w-full'} 
        ${parentHeight ? parentHeight : 'h-full'} 
        ${customParentClasses ? customParentClasses : ''} 
        max-h-full 
    `}>
        <button 
          onClick={clickHandler ? () => clickHandler() : ()=> {}} 
          className={`relative flex 
            ${width ? `${width}` : 'w-8'} 
            ${width ? `${height}` : 'h-8'} 
            ${bgColor ? `${bgColor}` : 'bg-white'} 
            ${customButtonClasses ? customButtonClasses : '' } 
            overflow-hidden rounded-full flex justify-center items-center 
        `}>
            <Icon style={{ 'fontSize': iconFontSize ? iconFontSize : '16px', 'color': iconColor ? iconColor : '#666' }} />
        </button>
    </div>
  )
}

export default ButtonWithCenterIcon
