import React from 'react'
import MenuBar from './MenuBar'
import DoctorsAccountBar from './doctorComponents/DoctorsAccountBar'

const TopBar = () => {
  return (
    <div className='w-full flex justify-around items-center px-4'>
      <MenuBar />
      <DoctorsAccountBar />
    </div>
  )
}

export default TopBar
