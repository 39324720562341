import { createSlice } from '@reduxjs/toolkit';

export const drawerSlice = createSlice({
	name: 'drawerState',
	initialState: {
		drawerState: false,
	},
	reducers: {
		changeDrawerState: {
			reducer: (state, action) => {
				state.drawerState = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		},
	}
})

// each case under reducers becomes an action
export const { changeDrawerState } = drawerSlice.actions

export default drawerSlice.reducer