import React from 'react'
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import HealingOutlinedIcon from '@mui/icons-material/HealingOutlined';

const CustomMedicalCard =  React.forwardRef(({ active, parentWidth, date, title, treatment, operation, icon }, ref) => {
    return (
        <div ref={ref} className={`${parentWidth ? parentWidth : 'w-[340px]'} ${active ? 'opacity-100' : 'opacity-40'} h-full flex flex-col gap-2 justify-between items-start px-3 py-4 bg-[#272D2D] rounded-[30px] text-[#fff] text-[12px]`}>
            <div className='w-full flex justify-between items-center'>
                <span className='p-4 bg-[#394141] flex justify-center items-center rounded-full'>
                    {icon ? 
                        icon 
                        :
                        <span className='p-1 rounded full bg-[#fff]'></span>
                    }
                </span>
                <div className='w-max'>{date}</div>
            </div>
            <div className='w-full '>
                <h4 className='capitalize'>{title}</h4>
                <div className='flex justify-start items-center gap-2'>
                    {treatment ? 
                        <p className='flex items-center justify-center gap-1'>
                            <VaccinesOutlinedIcon style={{ fontSize: '12px' }}/>
                            {treatment}
                        </p> 
                        : 
                        ''
                    }
                    {operation ? 
                        <p className='flex items-center justify-center gap-1'>
                            <HealingOutlinedIcon style={{ fontSize: '12px' }}/>
                            {operation}
                        </p> 
                        : 
                        ''
                    }
                </div>
            </div>
        </div>
    )
});

export default CustomMedicalCard
