import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { changeActivePatientAnatomy } from '../../slices/activePatientAnatomySlice';
import { ArcherElement } from 'react-archer';

const AnatomyIconWrapper = styled.div`
    position: relative;
    padding: ${({$activeState}) => ($activeState ? '8px' : '6px')};
    background-color: rgba(214, 214, 214, 0.8);
    backdrop-filter: blur(14px);
    transition: all 0.3s ease;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
`

const ChildrenWrapper = styled.div`
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    border-radius: 50%;
    transform: ${({ $active }) => ( $active ? 'translate(-50%, -50%) rotate(10deg) scale(1)' : 'translate(-50%, -50%) scale(0.1) rotate(150deg)')};
    transition: all 0.5s ease;
    -webkit-transform: ${({ $active }) => ( $active ? 'translate(-50%, -50%) rotate(10deg) scale(1)' : 'translate(-50%, -50%) scale(0.1) rotate(150deg)')};
    -moz-transform: ${({ $active }) => ( $active ? 'translate(-50%, -50%) rotate(10deg) scale(1)' : 'translate(-50%, -50%) scale(0.1) rotate(150deg)')};
    -ms-transform: ${({ $active }) => ( $active ? 'translate(-50%, -50%) rotate(10deg) scale(1)' : 'translate(-50%, -50%) scale(0.1) rotate(150deg)')};
    -o-transform: ${({ $active }) => ( $active ? 'translate(-50%, -50%) rotate(10deg) scale(1)' : 'translate(-50%, -50%) scale(0.1) rotate(150deg)')};
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
`

const ListItem = styled.li`
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 50%;
    transform-origin: 0 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    
`

const List = styled.ul`
    position: relative;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    transform: rotate(150deg);
    list-style: none;
    transition: all 0.3s ease;
`

const AnatomyIconComponent = forwardRef(({ name, icon, children, classes, child, defaultExpanded, changeDefaultExpansion, relations }, ref) => {
    const activeAnatomy = useSelector((state) => state.activePatientAnatomySlice.activePatientAnatomy); // Getting patientActiveAnatomy value from the store
	const activeAnatomyStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux
    const drawerState = useSelector((state) => state.drawerSlice.drawerState);
    const formDrawerState = useSelector((state) => state.formDrawerSlice.formDrawerState);
    const [childrenCount, setChildrenCount] = useState(0);
    const [targetId, setTargetId] = useState('heart');

    var regex = /\s+(\w)?/gi;

    function convertString(string) {
      var output = string.toLowerCase().replace(regex, function(match, letter) {
          return letter.toUpperCase();
      });

      return output
    }

    useEffect(()=> {
        // Count the children when activeAnatomy changes
        if (formDrawerState) {
            setTimeout(() => {
                setTargetId('formDrawer');
            }, 500);
        }
        else if(drawerState) {
            setTimeout(() => {
                setTargetId('customDrawer');
            }, 500);
        } else {
            setTargetId('heart');
        }
    }, [drawerState, formDrawerState]);

    useEffect(()=> {
        // Count the children when activeAnatomy changes
        setChildrenCount(React.Children.count(children));
    }, [activeAnatomy, children]);

    return (
        <div className={classes + ` flex justify-center items-center`}>
            <AnatomyIconWrapper $activeState={activeAnatomy === name ? true : false} onClick={() => {activeAnatomyStateDispatch(changeActivePatientAnatomy(name)); changeDefaultExpansion();}}>
                {activeAnatomy === name ?
                    <ArcherElement
                        id={name}
                        relations={relations}
                    >
                        <div className={`${child ? 'bg-[#888]' : 'bg-[#272d2d]'} rounded-full ${activeAnatomy === name ? 'p-3' : 'p-2'} transition-all duration-[0.3s] flex justify-center items-center z-[9]`}>
                            {icon}
                        </div>
                    </ArcherElement>
                    :
                    <div className={`${child ? 'bg-[#888]' : 'bg-[#272d2d]'} rounded-full ${activeAnatomy === name ? 'p-3' : 'p-2'} transition-all duration-[0.3s] flex justify-center items-center z-[9]`}>
                        {icon}
                    </div>
                }
                {child ? '' : (activeAnatomy === name && childrenCount > 0) ? '' : <span className='bg-[#f3ff47] text-[11px] px-[5px] py-[3px] flex justify-center items-center rounded-full leading-[1] absolute z-[999] -top-[2px] -left-[2px]'>{childrenCount}</span>}
                { children &&
                    <ChildrenWrapper $active={defaultExpanded ? activeAnatomy === name ? true: false : false}>
                        <List >
                            {children.map((item, i) => (
                                <ListItem key={i} style={{ transform: `${activeAnatomy === name ? `rotate(${i * 55}deg)` : `rotate(${i * 36}deg)`}` }}>
                                    <div className="absolute w-1/2 h-1/2 text-center rotate-45 pt-4">
                                        
                                            <div className="transform rotate-180">
                                                {activeAnatomy === name ? 
                                                    <ArcherElement
                                                        id={item.props.name + '' + i}
                                                        relations={[
                                                            {
                                                                targetId: convertString(item.props.name),
                                                                targetAnchor: 'left',
                                                                sourceAnchor: 'middle',
                                                            },
                                                        ]}
                                                    >
                                                        <a href="/" className="no-underline pointer-events-auto">
                                                            {item}
                                                        </a>
                                                    </ArcherElement>
                                                    :
                                                    <a href="/" className="no-underline pointer-events-auto">
                                                        {item}
                                                    </a>
                                                }
                                            </div>
                                    </div>
                                </ListItem>
                            ))}
                        </List>
                    </ChildrenWrapper>
                }
            </AnatomyIconWrapper>
        </div>
    )
});

export default AnatomyIconComponent
