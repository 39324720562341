import React, { useState } from 'react'
import patientAnatomy from '../../assets/images/PatientAnatomy01.png';
import AnatomyIconComponent from './AnatomyIconComponent';
import { ArmIcon, BrainIcon, HeartIcon, KneeIcon, StomachIcon } from '../../assets/icons/bodyPartsicons';
import { useDispatch, useSelector } from 'react-redux';

const anatomyArray = [
    {
        name: 'heart',
        classes: `absolute z-[999] top-[24%] left-[56%] -translate-x-[56%] -translate-y-[24%]`,
        icon: <HeartIcon fill={'#fff'} classes={'w-[18px] h-[16px]'} />,
        child: false,
        childrens: [
            {
                name: 'angina',
                classes: ``,
                icon: <HeartIcon fill={'#fff'} classes={'w-[18px] h-[16px]'} />,
                child: true,
            },
            {
                name: 'arrhythmia',
                classes: ``,
                icon: <HeartIcon fill={'#fff'} classes={'w-[18px] h-[16px]'} />,
                child: true,
            },
            {
                name: 'valve disease',
                classes: ``,
                icon: <HeartIcon fill={'#fff'} classes={'w-[18px] h-[16px]'} />,
                child: true,
            },
        ],
    },
    {
        name: 'brain',
        classes: `absolute z-[999] top-[1%] left-[46%] -translate-x-[46%] -translate-y-[1%]`,
        icon: <BrainIcon fill={'#fff'} classes={'w-[18px] h-[16px]'} />,
        child: false,
        childrens: [],
    },
    {
        name: 'stomach',
        classes: `absolute z-[999] top-[38%] left-[43%] -translate-x-[43%] -translate-y-[38%]`,
        icon: <StomachIcon fill={'#fff'} classes={'w-[18px] h-[16px]'} />,
        child: false,
        childrens: [],
    },
    {
        name: 'arm',
        classes: `absolute z-[999] top-[39%] left-[18%] -translate-x-[18%] -translate-y-[39%]`,
        icon: <ArmIcon fill={'#fff'} classes={'w-[18px] h-[16px]'} />,
        child: false,
        childrens: [],
    },
    {
        name: 'knee',
        classes: `absolute z-[999] top-[70%] left-[34%] -translate-x-[34%] -translate-y-[70%]`,
        icon: <KneeIcon fill={'#fff'} classes={'w-[18px] h-[16px]'} />,
        child: false,
        childrens: [],
    }
]

const PatientAnatomyWrapper = React.forwardRef(({ defaultExpand, relations }, ref) => {
    const activeAnatomy = useSelector((state) => state.activePatientAnatomySlice.activePatientAnatomy); // Getting patientActiveAnatomy value from the store
	const activeAnatomyStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux
    const [defaultExpanded, setDefaultExpanded] = useState(defaultExpand);

    return (
        <div className='relative flex w-full h-full justify-center items-center p-4'>
            <img className='block w-full h-full ' src={patientAnatomy} alt='patient-anatomy'></img>
            {
                anatomyArray.map((item, i) => {
                    return <AnatomyIconComponent 
                        key={`${item.name}${i}`}
                        name={item.name}
                        icon={item.icon} 
                        classes={item.classes}
                        child={item.child}
                        defaultExpanded={defaultExpanded}
                        changeDefaultExpansion = {() => {setDefaultExpanded(true)}}
                        relations = {relations}
                        ref={ref}
                    >
                            {item.childrens.length > 0 ? 
                                item.childrens.map((child, j) => {
                                    return <AnatomyIconComponent 
                                        key={`${child.name}${j}`}
                                        parentName={item.name}
                                        name = {child.name}
                                        icon = {child.icon} 
                                        classes = {child.classes} 
                                        child={child.child}
                                        ref={ref}
                                    ></AnatomyIconComponent>
                                })
                                :
                                undefined
                            }
                    </AnatomyIconComponent>
                })
            }
            
        </div>
    )
});

export default PatientAnatomyWrapper
