import React from 'react'
import PatientProfileDetails from './PatientProfileDetails';
import ProfileReportContent from './ProfileReportContent';

const PatientProfileReport = () => {
  return (
    <div className='relative w-full h-full flex flex-col justify-start items-center'>
      <PatientProfileDetails />
      <ProfileReportContent />
    </div>
  )
}

export default PatientProfileReport
