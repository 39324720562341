import React, { forwardRef, useEffect, useRef, useState } from 'react'
import TitleSubtitleComponent from '../reusableComponents/TitleSubtitleComponent'
import ReportIconComponent from '../reusableComponents/ReportIconComponent'
import CloseIconButton from '../reusableComponents/CloseIconButton'
import ReportImagesCard from '../reusableComponents/ReportImagesCard'
import { ArcherElement } from 'react-archer'
import Draggable from 'react-draggable'

const DoctorTestExams = forwardRef(({ 
    anatomyType,
    name, 
    type, 
    doctorIcon, 
    reportTitle, 
    reportDescription, 
    xPos,
    yPos,
    justifyDirection,
    reports,
    parentWidth,
    parentheight,
    flexGap,
    parentBgColor,
    roundedSize,
    setQuickNotesState,
    targetId,
    dismissDivCallback,
    handleRefresh
}, ref) => {
    const [activeReport, setActiveReport] = useState(null);
    const [activeChilds, setActiveChilds] = useState([]);
    const divRef = useRef(null); // Reference to the div

    const handleDismiss = (divId) => {
        if (divRef.current) {
            divRef.current.style.display = 'none'; // Hide the div
        }

        dismissDivCallback(divId)
    };

    const handleReportClick = (reportId) => {
        setActiveReport(reportId);
        const filteredReportsData = reports.filter((item, i) => item.reportId === reportId).map((item) => {return item.data})[0];
        const childArray = filteredReportsData.map((child) => { return child.dataId })

        setActiveChilds(childArray);
        setQuickNotesState();
    }

    const dismissChild = (childId) => {
        const newChild = activeChilds.filter((item) => item !== childId)
        setActiveChilds(newChild)
    }

    const handleDivClick = (e) => {
        const allDivs = document.querySelectorAll('.adjustable-div'); // Find all divs with this class name

        // Reset z-index for all divs
        allDivs.forEach((div) => {
            div.style.zIndex = '9'; // Set a low z-index for all divs
        });

        // Set a higher z-index for the clicked div
        divRef.current.style.zIndex = '99'
    };

    useEffect(() => {
        if (!activeChilds.length) {
            setActiveReport(null)
        }
    }, [activeChilds])

    return (
        <Draggable bounds={'parent'} onDrag={handleRefresh} onStop={handleRefresh}>
            <div ref={divRef} className={`adjustable-div 
                flex flex-col items-center justify-center absolute shadow-[0_0_10px_rgba(0,0,0,0.05)] 
                ${parentWidth ? parentWidth : 'w-full'}
                ${parentheight ? parentheight : 'h-full'}
                ${flexGap ? flexGap : ''}
                ${parentBgColor ? parentBgColor : 'bg-transparent'}
                ${roundedSize ? roundedSize : 'rounded-none'}
            `} style={{ top: yPos, left: xPos }} onClick={(e) => handleDivClick(e)}>
                <ArcherElement id={targetId}>
                    <CloseIconButton 
                        customParentClasses={`!absolute -top-1 ${justifyDirection === 'start' ? '-right-1' : '-left-1'} z-[999]`}
                        parentWidth={'w-max'} 
                        parentHeight={'h-max'}
                        width={'w-[30px]'} 
                        height={'h-[30px]'}
                        iconFontSize={14} 
                        iconColor={'#272d2d'}
                        bgColor={'bg-[#f3ff47]'}
                        clickHandler={() => {handleDismiss(targetId)}}
                        ref={ref}
                    />
                </ArcherElement>
                <div className='relative w-full h-full flex flex-col items-center justify-center gap-1'>
                    <div className={`
                        h-1/4 w-full flex items-center p-1 gap-2
                        ${justifyDirection === 'start' ? 'justify-start' : 'justify-end'} 
                        ${justifyDirection === 'start' ? 'flex-row' : 'flex-row-reverse'}
                    `}>
                        <div className='rounded-full w-[54px] h-[40px] overflow-hidden bg-[length:80px_50px] bg-[center_top] bg-no-repeat' style={{ backgroundImage : `url(${doctorIcon})`}}></div>
                        <TitleSubtitleComponent 
                            parentFlexGap = {'gap-[3px]'}
                            parentPadding={'py-2'}
                            titleText={name} 
                            subText={type} 
                            titleFontSize={'text-[12px]'} 
                            subTitleFontSize={'text-[10px]'} 
                            titleFontWeight = {'font-[400]'}
                            subTitleFontWeight = {'font-[400]'}
                            subTitleTextColor = {'text-[#666]'}
                            alignItemsDirection={justifyDirection === 'start' ? 'items-start' : 'items-end'}
                        />
                    </div>
                    <div className={`h-3/4 flex flex-col justify-center items-center gap-1 rounded-[30px] overflow-hidden p-4`}>
                        <div className='w-full flex justify-between items-center'>
                            <p className='text-[#000] !text-[14px] capitalize'>{reportTitle}</p>
                        </div>
                        <div className='w-full flex flex-wrap justify-between items-center'>
                            <p className='text-[#666] capitalize text-[12px]'>
                                {reportDescription}
                            </p>
                        </div>
                        <div className='w-full flex justify-start items-center'>
                            {
                                reports.map((item, j) => (
                                    <ReportIconComponent 
                                        key={`${item.reportId}`}
                                        bg_color = {'bg-[#fff]'}
                                        Icon = {item.icon}
                                        text = {item.data.length}
                                        fontColor = {'text-[#444]'}
                                        reportId = {item.reportId}
                                        activeReport = {activeReport}
                                        activeBgColor={'bg-[#272d2d]'}
                                        changeActiveReport = {(value) => {handleReportClick(value)}}
                                    />
                                ))
                            }
                        </div>
                    </div>

                    {activeReport ? 
                        reports.filter((item, i) => item.reportId === activeReport).map((item, i) => (
                            item.data.map((element, j) => (
                                <ReportImagesCard 
                                    key={`${element.name}${j}`}
                                    childId = {element.dataId}
                                    xPos = {0}
                                    yPos = {`${j === 0 ? 101 : (81 + (101 * j))}%`}
                                    name = {element.name}
                                    subTitle = {element.value}
                                    parentheight = {`${element.image.length === 1 || element.image.length >= 4 ? 'h-[80%]' : 'h-[50%]' }`}
                                    parentBgColor = {'bg-[#fff]'}
                                    reportImages = {element.image}
                                    dismissChild = {(childId) => {dismissChild(childId)}}
                                />
                            ))
                        ))
                        :
                        ''
                    }
                </div>
            </div>
        </Draggable>
    )
});

export default DoctorTestExams
