import React from 'react'
import DoctorProfileBadge from '../doctorComponents/DoctorProfileBadge'
import ButtonWithCenterIcon from '../ButtonWithCenterIcon'

const DoctorExams = ({ image, name, type, exams }) => {
    return (
        <div className={`relative flex flex-col h-full`}>
            <DoctorProfileBadge 
                image={image} 
                altText={name} 
                name={name} 
                subtitleText={type} 
                bgColor={'bg-[#fafafa]'}
            />
            <div className='w-full h-full flex justify-start items-start px-2 gap-1'>
                {
                    exams.map((item, i) => (
                        <div key={`${item.text}${i}`} className='h-full w-max flex flex-col justify-start items-center'>
                            <span className='w-[1.2px] h-1/3 bg-[#272d2d]'></span>
                            <div className="relative bg-[#fff] rounded-[30px] w-max h-max flex justify-center items-center z-[9] hover:z-[999] transition-all duration-500">
                                <div className="relative bg-[#272d2d] z-[999] rounded-full w-full h-full flex justify-center items-center peer cursor-pointer">
                                    <ButtonWithCenterIcon bgColor={'bg-transparent'} iconColor={'#fff'} parentWidth={'w-max'} width={'w-[24px]'} height={'h-[24px]'} icon={item.icon} iconFontSize={14} />
                                </div>
                                {item.text || item.status ?
                                    <p className='w-max h-full rounded-[30px] absolute left-0 top-0 flex justify-center items-center bg-[#fff] 
                                        scale-x-0 origin-[center_left] text-[0px] transition-all duration-200 
                                        peer-hover:pl-[120%] peer-hover:pr-[5px] peer-hover:text-[10px] peer-hover:scale-x-100'>
                                        {item.text ? item.text : ''} 
                                        {item.status ? ` (${item.status})` : ''}
                                    </p>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default DoctorExams
