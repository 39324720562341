import React, { useRef } from 'react'
import image from '../../assets/images/doctorImage1-removebg-preview.png'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import DoctorProfileBadge from '../doctorComponents/DoctorProfileBadge'
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import CustomChips from '../CustomChips';
import CustomMedicalCard from '../CustomMedicalCard';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import HvacOutlinedIcon from '@mui/icons-material/HvacOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ComplaintCards from './ComplaintCards';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CustomTimeline from '../reusableComponents/CustomTimeline';
import { CircleIcon } from '../../assets/icons/MedicalIcons';
import { useDispatch, useSelector } from 'react-redux';
import { changeFormDrawerState } from '../../slices/formDrawerSlice';
import AddComplaintForm from './AddComplaintForm';

const linkedDocsData = [
    {icon: <ScienceOutlinedIcon fontSize='14px' />, date: '19/10/2023', name : 'prothrombin', treatment: 1, operation: 1},
    {icon: <HvacOutlinedIcon fontSize='14px' />, date: '15/09/2023', name : 'MRI', treatment: 2, operation: 1},
    {icon: <DescriptionOutlinedIcon fontSize='14px' />, date: '22/09/2023', name : 'Neurology', treatment: 1, operation: 0}
]

const complaintCardsdata = [
    {date: '11/12/2023', title: 'acute heart pain', description: 'A patient with acute heart failures is in stable clinical condition and according to MRI, there is suddden acute pain when moving under load. I think he would benefit from trying exercises with a physical therapist, and i give him a referral for that'},
    {date: '09/12/2023', title: 'fainting', description: 'sudden fainting when climbing stairs, hoarseness in the chest when listening with an endoscope'}
]

const treatmentsData = [
    {title: 'coronary disease'},
    {title: 'Arterial spasm'},
]

const evaluativeIndicatorGraphData = [
    {date: '14/01/2023', amt: 60, data : [ {type: 'labs', label: 'Binimentinib', icon: <CircleIcon width={6} height={6} fill={'#fff'} />} ]},
    {date: '24/03/2023', amt: 40, data : [ {type: 'docs', label: 'Visit', icon: <CircleIcon width={6} height={6} fill={'#fff'} />} ]},
    {date: '05/06/2023', amt: 60, data : [ {type: 'docs', label: 'visit', icon: <CircleIcon width={6} height={6} fill={'#fff'} />} ]},
    {date: '03/09/2023', amt: 20, data : [ {type: 'labs', label: 'Aspirin', icon: <CircleIcon width={6} height={6} fill={'#fff'} />} ]},
    {date: '08/12/2023', amt: 50, data : [ {type: 'docs', label: 'Visit', icon: <CircleIcon width={6} height={6} fill={'#fff'} />} ]},
]

const calculatePosition = (date) => {
    const [day, month] = date.split('/');
    const fractionalValue = (parseInt(day) - 1) / 30; // Assuming 30 days in a month
    return parseInt(month - 1) + fractionalValue;
  };

const adjustedData = evaluativeIndicatorGraphData.map(item => ({
    ...item,
    position: calculatePosition(item.date)
}));

const DocsContentWrapper = () => {
    const containerRef = useRef();
    const formDrawerState = useSelector((state) => state.formDrawerSlice.formDrawerState);
    const formDrawerDispatch = useDispatch();
    const monthsInYear = Array.from({ length: 13 }, (_, i) => i); // Array of numbers from 1 to 12

    const toggleFormDrawer = () => {
        formDrawerDispatch(changeFormDrawerState({formDrawerState: !formDrawerState, formDrawerContentType: 'addComplaintForm'}));
    }

    return (
        <div className='relative w-full h-full flex flex-col justify-start items-start'>
            <div className='h-1/4 w-full flex justify-start items-start gap-4 pb-2'>
                <div className='w-1/4 px-10 h-full bg-[#f6f6f6] rounded-[30px] flex flex-col justify-center items-start'>
                    <p className='text-[12px]'>12.12.2023</p>
                    <h2 className='text-[22px] whitespace-normal'>Office visit: Cardiology</h2>
                </div>
                <div className='w-3/4 h-full flex flex-col justify-center items-start gap-2'>
                    <div className='w-full flex justify-start items-start gap-1'>
                        <div className='w-max rounded-[20px] bg-[#f4f4f4] text-[14px] shadow-sm'>
                            <DoctorProfileBadge 
                                image={image} 
                                altText={'dr. peterson'} 
                                name={'dr. peterson'} 
                                subtitleText={'Neurologist'} 
                                bgColor={'transparent'}
                            />
                        </div>
                        <ButtonWithCenterIcon customButtonClasses={'shadow-sm'} parentWidth={'w-max'} icon={PhoneOutlinedIcon} bgColor={'bg-[#f4f4f4]'} />
                        <ButtonWithCenterIcon customButtonClasses={'shadow-sm'} parentWidth={'w-max'} icon={TextsmsOutlinedIcon} bgColor={'bg-[#f4f4f4]'} />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p>docs</p>
                            <p>03</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p>meds</p>
                            <p>02</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p>labs</p>
                            <p>01</p>
                        </CustomChips>
                        <CustomChips customClasses={'flex gap-2 leading-none capitalize'} bgColor={'bg-[#fafafa]'}>
                            <p>operations</p>
                            <p>01</p>
                        </CustomChips>
                    </div>
                </div>
            </div>
            <div className='h-3/4 w-full bg-[#f0f0f0] flex gap-2 py-3'>
                <div className='w-1/4 h-full flex flex-col gap-[2px] px-3'>
                    <p className='capitalize text-[12px] font-medium mb-1'>linked documents</p>
                    {
                        linkedDocsData.map((item, i) => 
                            <CustomMedicalCard 
                                key= {`${item.name}${i}`}
                                icon= {item.icon}
                                date= {item.date}
                                title= {item.name}
                                treatment= {item.treatment}
                                operation= {item.operation}
                                parentWidth= {'w-full'}
                            />
                        )
                    }
                </div>
                <div className='w-3/4 h-full flex flex-col gap-2 px-3'>
                    <div className='w-full h-max'>
                        <p className='capitalize text-[12px] font-medium mb-1'>complaints</p>
                        <div className='relative flex h-auto gap-2 items-stretch'>
                            {
                                complaintCardsdata.map((item, i) => 
                                    <ComplaintCards 
                                        key={`${item.title}${i}`}
                                        active={true}
                                        parentHeight={'h-auto'}
                                        parentWidth={item.description.length > 100 ? 'w-[240px]' : 'w-[200px]'}
                                        date={item.date}
                                        title={item.title}
                                        description={item.description}
                                    />
                                )
                            }
                            <ButtonWithCenterIcon 
                                parentWidth={'w-auto'} 
                                parentHeight={'h-auto'} 
                                width={'w-[52px]'} 
                                height={'h-[52px]'} 
                                icon={AddRoundedIcon} 
                                iconFontSize={24} 
                                customParentClasses='flex items-center'
                                clickHandler={() => toggleFormDrawer()}
                            />
                        </div>
                    </div>
                    <div className='w-full h-max'>
                        <p className='capitalize text-[12px] font-medium mb-1'>treatments</p>
                        <div className='flex h-auto gap-2 items-center'>
                            {
                                treatmentsData.map((item, i) => 
                                    <CustomChips 
                                        key={`${item.title}${i}`}
                                        customClasses={'flex gap-2 leading-none capitalize'} 
                                        bgColor={'bg-[#fafafa]'}
                                    >
                                        <p className=''>{item.title}</p>
                                    </CustomChips>
                                )
                            }
                            <ButtonWithCenterIcon 
                                parentWidth={'w-auto'} 
                                parentHeight={'h-auto'} 
                                icon={AddRoundedIcon} 
                                iconFontSize={16} 
                                customParentClasses='flex items-center'
                            />
                        </div>
                    </div>
                    <div className='w-full h-full flex flex-col'>
                        <p className='capitalize text-[12px] font-medium mb-1'>evaluative indicators</p>
                        <div className='flex w-full flex-1 min-h-0 h-full gap-2 items-center'>
                            <CustomTimeline
                                ref = {containerRef}
                                graphData={adjustedData}
                                ticks={monthsInYear}
                                parentDivHeight={'h-full'}
                                graphMargins = {{right: 20, top: 0, bottom: 0, left: 10}}
                                customIdentifier={'evaluative'}
                                showAxisLine = {true}
                                showTicks = {true}
                                showTickLine = {true}
                            />
                            <ButtonWithCenterIcon 
                                parentWidth={'w-auto'} 
                                parentHeight={'h-auto'} 
                                icon={AddRoundedIcon} 
                                iconFontSize={16} 
                                customParentClasses='flex items-center'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocsContentWrapper
