import React from 'react'

const DoctorProfileBadge = ({ image, altText, name, subtitleText, parentPadding, bgColor, flexDirection }) => {
  return (
    <div className={`
      flex justify-center items-center 
      gap-4 rounded-[100px] 
      ${parentPadding ? parentPadding : 'px-4'}
      ${bgColor ? bgColor : 'bg-[#ececec]'} 
      ${flexDirection ? flexDirection : 'flex-row'}
    `}>
        <div className='max-h-full'>
          <a className='relative flex w-8 h-8 overflow-hidden bg-transparent' href='/'>
            <img className='relative block h-full w-full' src={image} alt={altText} />
          </a>
        </div>
        <div className='flex flex-col justify-center items-start'>
          <p className='font-light text-[10px] p-0 m-0 leading-none capitalize'>{subtitleText}</p>
          <p className='capitalize p-0 m-0 leading-none whitespace-nowrap text-[12px]'>{name}</p>
        </div>
    </div>
  )
}

export default DoctorProfileBadge
