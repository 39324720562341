import { jwtDecode } from "jwt-decode";

// Function to check if a token has expired
export const isTokenExpired = (token) => {
    if (!token) return true; // No token means it's expired

    try {
        const decodedToken = jwtDecode(token); // Decode the token
        const currentTime = Math.floor(Date.now() / 1000); // Get the current time in seconds

        return decodedToken.exp < currentTime; // Return true if token has expired
    } catch (error) {
        console.error('Failed to decode token:', error);
        return true; // If decoding fails, consider it expired
    }
};
