import React from 'react';
import styled, { keyframes } from 'styled-components';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';

const fadeIn = keyframes`
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 400px; /* Adjust the max-height as needed */
  }
`;

const fadeInFull = keyframes`
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100%; /* Adjust the max-height as needed */
    overflow: scroll;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    max-height: 100px; /* Adjust the max-height to match the expanded height */
  }
  to {
    opacity: 0;
    max-height: 0;
  }
`;

const AccordionContainer = styled.div`
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    background-color: rgba(248, 248, 248, 0.8);
    backdrop-filter: blur(14px);
    transition: all 0.3s ease;
    box-shadow: ${({ $parentShadow }) => ($parentShadow ? $parentShadow : '0px 0px 10px rgba(0, 0, 0, 0.02)' )}; 
`;

const AccordionHeader = styled.div`
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  padding: 5px 18px;
  background-color: ${({ $accordionHeaderColor }) => ($accordionHeaderColor ? $accordionHeaderColor : 'bg-transparent') };
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: ${({ $isopen, $keepHeaderRounded }) => ($keepHeaderRounded ? '30px' : $isopen ? '0px' : '30px')};
  border-bottom-right-radius: ${({ $isopen, $keepHeaderRounded }) => ($keepHeaderRounded ? '30px' : $isopen ? '0px' : '30px')};
  overflow: hidden;
  display: flex;
  justify-content: ${({$staticheader, $showCloseButton}) => ($staticheader && $showCloseButton ? 'flex-end' : 'space-between')};
  align-items: center;
  transition: all 0.3s ease;
`;

const AccordionContent = styled.div`
    overflow: hidden;
    animation: ${({ $isopen, $maxContentHeight }) => ($isopen ? $maxContentHeight ? fadeInFull : fadeIn : fadeOut)} 0.5s ease forwards;

    /* Hide scrollbar for Webkit browsers */
    &::-webkit-scrollbar {
      display: none; /* Hides the scrollbar */
    }

    /* Hide scrollbar for Firefox */
    scrollbar-width: none;
`;

const CustomInfoAccordion = ({ 
  title, 
  children, 
  onClick, 
  isopen, 
  headerComponent, 
  parentContainerClasses, 
  parentShadow,
  staticHeaderComponent, 
  buttonClickHandler, 
  showCloseButton, 
  maxContentHeight,
  accordionHeaderColor,
  keepHeaderRounded,
}) => {

    return (
        <AccordionContainer className={`${parentContainerClasses ? parentContainerClasses : ''} ${staticHeaderComponent ? '!overflow-visible' : ''}`} $parentShadow={parentShadow}>
            {staticHeaderComponent && isopen ? 
              showCloseButton ?
                <ButtonWithCenterIcon 
                    customParentClasses={'!absolute -top-1 -left-1'}
                    parentWidth={'w-max'} 
                    width={'w-[30px]'} 
                    height={'h-[30px]'} 
                    icon={CloseFullscreenOutlinedIcon} 
                    iconFontSize={14} 
                    iconColor={'#272d2d'}
                    bgColor={'bg-[#f3ff47]'}
                    clickHandler={buttonClickHandler ? buttonClickHandler : () => {}}
                />
                :
                ''
              : 
              ''
            }
            <AccordionHeader 
              onClick={onClick} 
              $isopen={isopen} 
              $staticheader={staticHeaderComponent} 
              $showCloseButton={showCloseButton} 
              $accordionHeaderColor={accordionHeaderColor}
              $keepHeaderRounded={keepHeaderRounded}
            >
              {staticHeaderComponent ? 
                headerComponent
                :
                <>
                  <span className='text-[#000] py-2'>{title}</span>
                  {isopen ? <CloseFullscreenOutlinedIcon style={{ fontSize: '14px' }} /> : headerComponent }
                </>
              }
            </AccordionHeader>
            <AccordionContent $isopen={isopen} $maxContentHeight={maxContentHeight}>
                <div className='rounded-[30px] overflow-hidden'>
                  {children}
                </div>
            </AccordionContent>
        </AccordionContainer>
    );
};

export default CustomInfoAccordion;
