import React from 'react'
import { ArcherElement } from 'react-archer';
import CustomMedicalCard from './CustomMedicalCard';
import { useSelector } from 'react-redux';

const TimelineWrapper = React.forwardRef(( {children, id, title, date, treatment, operation, active}, ref ) => {
    const drawerState = useSelector((state) => state.drawerSlice.drawerState);

    return (
        <div className={`w-full ${active ? 'h-full ml-0' : 'h-full ml-10'} flex items-center justify-between gap-2 bg-[#ececec] rounded-tl-[30px] rounded-bl-[30px] overflow-hidden`}>
            {drawerState ? 
                <CustomMedicalCard 
                    parentWidth={'w-[240px]'}
                    active={active}
                    title = {title}
                    date = {date}
                    treatment = {treatment}
                    operation = {operation}
                    ref={ref}
                />
                :
                <ArcherElement id={id} ref={ref}>
                    <CustomMedicalCard 
                        parentWidth={'w-[240px]'}
                        active={active}
                        title = {title}
                        date = {date}
                        treatment = {treatment}
                        operation = {operation}
                        ref={ref}
                    />
                </ArcherElement>
            }
            <div className='w-full h-full'>
                {children}
            </div>
        </div>
    )
});

export default TimelineWrapper
