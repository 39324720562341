import React from 'react'
import PatientDetailedData from './patientComponents/PatientDetailedData'
import TopBar from './TopBar'
import { useSelector } from 'react-redux';
import CommunicationContentMain from './doctorComponents/CommunicationContentMain';
import ConsultationContentMain from './consultationComponents/ConsultationContentMain';

const contentObject = {
  'overview' : <PatientDetailedData />,
  'notes' : <PatientDetailedData />,
  'docs' : <PatientDetailedData />,
  'labs' : <PatientDetailedData />,
  'imaging' : <PatientDetailedData />,
  'consultation' : <ConsultationContentMain />,
  'communication' : <CommunicationContentMain />
}

const ContentWrapper = () => {
  const navState = useSelector((state) => state.navSlice.navState); // Getting navstate value from the store

  return (
    <div className='relative pl-4 w-full h-full max-h-full flex flex-col justify-start items-start overflow-hidden'>
      <TopBar />
      {contentObject[navState]}
    </div>
  )
}

export default ContentWrapper
