import './App.css';
import Main from './pages/Main';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import AuthWrapper from './components/AuthWrapper';


function App() {
  return (
    <div className={`App bg-gradient-to-b from-[#DFDFD7] to-[#E4E4DC] overflow-hidden`}>
      <AuthWrapper>
        <Main />
      </AuthWrapper>
    </div>
  );
}

export default App;
