import { createSlice } from '@reduxjs/toolkit';

export const activePatientAnatomy = createSlice({
	name: 'activePatientAnatomy',
	initialState: {
		activePatientAnatomy: 'heart',
	},
	reducers: {
		changeActivePatientAnatomy: {
			reducer: (state, action) => {
				state.activePatientAnatomy = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		}
	}
})

// each case under reducers becomes an action
export const { changeActivePatientAnatomy } = activePatientAnatomy.actions

export default activePatientAnatomy.reducer