import React from 'react'
import styled from 'styled-components'
import image from '../../assets/images/png-image.png'

const ProfileImageWrapper = styled.div`
  position: absolute;
  width: 85%;
  left: -22%;
  bottom: 0;
`

const ProfilePersonalDetailsWrapper = styled.div`
  position: absolute;
  width: 50%;
  height: max-content;
  top: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
`

const ProfileName = styled.h3`
  color: #000;
  font-size: 14px;
  white-space: break-spaces;
  line-height: 1.2;
`

const GenderAge = styled.p`
  font-size: 10px;
`

const BloodGroupBadge = styled.span`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 10px;
  width: max-content;
  background-color: #fff;
  line-height: 1.2;
`

const HeightWeightBadge = styled.span`
  border-radius: 30px;
  display: flex;
  justify-content: center;
  line-height: 1.2;
  align-items: center;
  padding: 5px 10px;
  font-size: 10px;
  width: max-content;
  background-color: #fff;
`


const PatientProfileDetails = () => {
  return (
    <div className='relative w-full bg-[#ececec] h-1/4 rounded-[30px] overflow-hidden'>

      <ProfileImageWrapper>
        <img src={image} alt='patient-pic'></img>
      </ProfileImageWrapper>
      
      <ProfilePersonalDetailsWrapper>

        <div className=''>
          <ProfileName>James Heatfield</ProfileName>
          <GenderAge>Male, 34</GenderAge>
        </div>

        <div className='flex justify-start items-start gap-1'>
          <BloodGroupBadge>A+</BloodGroupBadge>
          <HeightWeightBadge>5'11 / 160</HeightWeightBadge>
        </div>
        
      </ProfilePersonalDetailsWrapper>

    </div>
  )
}

export default PatientProfileDetails
