import React, { useState } from 'react'
import BubbleTimelineGraph from './BubbleTimelineGraph';
import { Range, getTrackBackground } from 'react-range';
import { useDispatch, useSelector } from 'react-redux';
import { changeYearTimelineState, changeYearTimelineDurationState } from '../slices/yearTimelineSlice';

const timelineJson = [
    { 
        '2023': [
            {   "id": 0, "name": "heart", "category": "medium", "date": "2023-03",  },
            {   "id": 1, "name": "arm", "category": "critical", "date": "2023-05",  },
            {   "id": 2, "name": "heart", "category": "medium", "date": "2023-07",  },
            {   "id": 3, "name": "brain", "category": "low", "date": "2023-08",     },
            {   "id": 4, "name": "stomach", "category": "critical", "date": "2023-10",  },
            {   "id": 5, "name": "knee", "category": "high", "date": "2023-12",     },
        ],
    },
    { 
        '2022': [
            {   "id": 0, "name": "heart", "category": "low", "date": "2022-03",  },
            {   "id": 1, "name": "arm", "category": "medium", "date": "2022-05",  },
            {   "id": 2, "name": "heart", "category": "high", "date": "2022-07",  },
            {   "id": 3, "name": "brain", "category": "critical", "date": "2022-08",     },
            {   "id": 4, "name": "stomach", "category": "medium", "date": "2022-10",  },
            {   "id": 5, "name": "knee", "category": "critical", "date": "2022-12",     },
        ],
    },
    { 
        '2021': [
            {   "id": 0, "name": "heart", "category": "medium", "date": "2021-03",  },
            {   "id": 1, "name": "arm", "category": "hight", "date": "2021-05",  },
            {   "id": 2, "name": "heart", "category": "critical", "date": "2021-07",  },
            {   "id": 3, "name": "brain", "category": "low", "date": "2021-08",     },
            {   "id": 4, "name": "stomach", "category": "high", "date": "2021-10",  },
            {   "id": 5, "name": "knee", "category": "low", "date": "2021-12",     },
        ],
    },
    { 
        '2020': [
            {   "id": 0, "name": "heart", "category": "medium", "date": "2020-03",  },
            {   "id": 1, "name": "arm", "category": "critical", "date": "2020-05",  },
            {   "id": 2, "name": "heart", "category": "medium", "date": "2020-07",  },
            {   "id": 3, "name": "brain", "category": "low", "date": "2020-08",     },
            {   "id": 4, "name": "stomach", "category": "critical", "date": "2020-10",  },
            {   "id": 5, "name": "knee", "category": "high", "date": "2020-12",     },
        ],
    },
    { 
        '2019': [
            {   "id": 0, "name": "heart", "category": "low", "date": "2019-03",  },
            {   "id": 1, "name": "arm", "category": "medium", "date": "2019-05",  },
            {   "id": 2, "name": "heart", "category": "high", "date": "2019-07",  },
            {   "id": 3, "name": "brain", "category": "critical", "date": "2019-08",     },
            {   "id": 4, "name": "stomach", "category": "medium", "date": "2019-10",  },
            {   "id": 5, "name": "knee", "category": "critical", "date": "2019-12",     },
        ],
    },
    { 
        '2018': [
            {   "id": 0, "name": "heart", "category": "medium", "date": "2018-03",  },
            {   "id": 1, "name": "arm", "category": "hight", "date": "2018-05",  },
            {   "id": 2, "name": "heart", "category": "critical", "date": "2018-07",  },
            {   "id": 3, "name": "brain", "category": "low", "date": "2018-08",     },
            {   "id": 4, "name": "stomach", "category": "high", "date": "2018-10",  },
            {   "id": 5, "name": "knee", "category": "low", "date": "2018-12",     },
        ],
    },
    { 
        '2017': [
            {   "id": 0, "name": "heart", "category": "medium", "date": "2017-03",  },
            {   "id": 1, "name": "arm", "category": "critical", "date": "2017-05",  },
            {   "id": 2, "name": "heart", "category": "medium", "date": "2017-07",  },
            {   "id": 3, "name": "brain", "category": "low", "date": "2017-08",     },
            {   "id": 4, "name": "stomach", "category": "critical", "date": "2017-10",  },
            {   "id": 5, "name": "knee", "category": "high", "date": "2017-12",     },
        ],
    },
    { 
        '2016': [
            {   "id": 0, "name": "heart", "category": "low", "date": "2016-03",  },
            {   "id": 1, "name": "arm", "category": "medium", "date": "2016-05",  },
            {   "id": 2, "name": "heart", "category": "high", "date": "2016-07",  },
            {   "id": 3, "name": "brain", "category": "critical", "date": "2016-08",     },
            {   "id": 4, "name": "stomach", "category": "medium", "date": "2016-10",  },
            {   "id": 5, "name": "knee", "category": "critical", "date": "2016-12",     },
        ],
    },
    { 
        '2015': [
            {   "id": 0, "name": "heart", "category": "medium", "date": "2015-03",  },
            {   "id": 1, "name": "arm", "category": "hight", "date": "2015-05",  },
            {   "id": 2, "name": "heart", "category": "critical", "date": "2015-07",  },
            {   "id": 3, "name": "brain", "category": "low", "date": "2015-08",     },
            {   "id": 4, "name": "stomach", "category": "high", "date": "2015-10",  },
            {   "id": 5, "name": "knee", "category": "low", "date": "2015-12",     },
        ],
    },
    { 
        '2014': [
            {   "id": 0, "name": "heart", "category": "medium", "date": "2014-03",  },
            {   "id": 1, "name": "arm", "category": "critical", "date": "2014-05",  },
            {   "id": 2, "name": "heart", "category": "medium", "date": "2014-07",  },
            {   "id": 3, "name": "brain", "category": "low", "date": "2014-08",     },
            {   "id": 4, "name": "stomach", "category": "critical", "date": "2014-10",  },
            {   "id": 5, "name": "knee", "category": "high", "date": "2014-12",     },
        ],
    },
]

const BubbleTimelineGraphWrapper = ({ rtl }) => {
    const yearTimelineState = useSelector((state) => state.yearTimelineReducer.yearTimelineState.yearTimelineState); // Getting state value from the store
    const yearTimelineDuration = useSelector((state) => state.yearTimelineReducer.yearTimelineDurationState.yearTimelineDurationState); // Getting state value from the store
	const yearTimelineStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux
    const yearTimelineDurationStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux

    const step = 1; // Each step represents a month
    const min = 0;
    const max = timelineJson.length * 12; // Let's set it for 10 years for demonstration purposes

    const handleValuesChange = (newValues) => {
        const currentYear = Object.keys(timelineJson[newValues[0]])[0]
        const currentYearTimestamp = (new Date(currentYear, 0)).getTime();

        yearTimelineDurationStateDispatch(changeYearTimelineDurationState(newValues.slice()));
        
        yearTimelineStateDispatch(changeYearTimelineState(currentYearTimestamp));
    };

    return (
        <div className='w-full h-full flex justify-start items-center gap-0 px-[22px]'>
            <Range
                draggableTrack
                step={step}
                min={min}
                max={max}
                rtl={rtl}
                values={yearTimelineDuration}
                onChange={handleValuesChange}
                renderMark={({ props, index }) => {
                    const isLastMark = index === max;
                    if(index % 12 === 0) {
                        return (
                            <div
                            {...props}
                            style={{
                                ...props.style,
                                transform: 'translateX(54%)',
                                height: 'max-content',
                                width: isLastMark ? '0px' : 'max-content',
                                backgroundColor: 'transparent'
                            }}
                            >
                                {index < max && (
                                    <BubbleTimelineGraph 
                                        key = {index} 
                                        active = {index === 0 ? true : false} 
                                        fillColor = {(index >= yearTimelineDuration[0] && index < yearTimelineDuration[1]) ? '#2F3737' : '#dfdfd7'}
                                        strokeColor = {(index >= yearTimelineDuration[0] && index < yearTimelineDuration[1]) ? '#BEC6C6' : '#fff'}
                                        yearTextColor = {(index >= yearTimelineDuration[0] && index < yearTimelineDuration[1]) ? '#272d2d' : '#272d2d'}
                                        idCount = {index / 12} 
                                        data = {timelineJson[index / 12]} 
                                        thumbValues={yearTimelineDuration} // Pass the yearTimelineDuration state as a prop
                                    />
                                )}
                            </div>
                        )
                    } else {
                        return ''
                    }
                }}
                renderTrack={({ props, children }) => {
                    return (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '100%',
                                display: 'flex',
                                width: '100%'
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values: yearTimelineDuration,
                                        colors: ['transparent', '#BEC6C6', 'transparent'],
                                        min: min,
                                        max: max,
                                        rtl
                                    }),
                                    alignSelf: 'center'
                                }}
                                >
                                {children}
                            </div>
                        </div>
                    )}
                }
                renderThumb={({ props, isDragged }) => {
                    
                    return (
                        <div
                            {...props}
                            className='yearTimelineThumb'
                            style={{
                                ...props.style,
                                height: '110%',
                                width: '45px',
                                left: props.key === 0 ? '-22px' : '22px',
                                clipPath: props.key === 0 ? 'circle(50.0% at 100% 50%)' : 'circle(50.0% at 0 50%)',
                                backgroundColor: '#BEC6C6',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: props.key === 0 ? '80%' : '20%',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    height: '20px',
                                    width: '2px',
                                    backgroundColor: isDragged ? '#fff' : '#fff'
                                }}
                            />
                        </div>
                    )
                }}
            />
        </div>
    )
}

export default BubbleTimelineGraphWrapper
