import { createSlice } from '@reduxjs/toolkit';

export const docsTabsSlice = createSlice({
	name: 'docsTabState',
	initialState: {
		docsTabState: 'cardiology',
	},
	reducers: {
		changeDocsTabState: {
			reducer: (state, action) => {
				state.docsTabState = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		}
	}
})

// each case under reducers becomes an action
export const { changeDocsTabState } = docsTabsSlice.actions

export default docsTabsSlice.reducer