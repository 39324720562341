import React from 'react'
import xRayImage from '../../assets/images/Xray_share.jpeg'
import ButtonWithCenterIcon from '../ButtonWithCenterIcon'
// import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import styled, { keyframes } from 'styled-components';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';
import { TextField } from '@mui/material';

// Custom-styled TextField with no outline on focus
const CustomTextField = styled(TextField)`
    width: 100%;
    & .MuiOutlinedInput-root {
        font-size: 12px;
        width: 100%;
        /* Override the default border on focus */
        &.Mui-focused {
            outline: none; /* No outline */
            border-color: transparent; /* Optional: make border transparent */
            box-shadow: none; /* Remove shadow or glow effects */
        }

        /* Remove hover effects */
        &:hover {
            border-color: transparent; /* Optional: make border transparent */
        }
    }
    
    & .MuiOutlinedInput-notchedOutline {
        outline: none;
        border: none;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    min-height: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    height: 400px;
    min-height: 400px; /* Adjust the min-height as needed */
    max-height: 400px; /* Adjust the max-height as needed */
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 400px;
    min-height: 400px; /* Adjust the min-height to match the expanded height */
    max-height: 400px; /* Adjust the max-height to match the expanded height */
  }
  to {
    opacity: 0;
    min-height: 0;
    max-height: 0;
  }
`;

const AccordionContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99999;
    width: 25%;
    border-radius: 30px;
    overflow: visible;
    background-color: #ececec;
    backdrop-filter: blur(14px);
    transition: all 0.3s ease;
`;

const AccordionHeader = styled.div`
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  padding: 5px 18px;
  background-color: ${({ $accordionHeaderColor }) => ($accordionHeaderColor ? $accordionHeaderColor : 'bg-transparent') };
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: ${({ $isopen, $keepHeaderRounded }) => ($keepHeaderRounded ? '30px' : $isopen ? '0px' : '30px')};
  border-bottom-right-radius: ${({ $isopen, $keepHeaderRounded }) => ($keepHeaderRounded ? '30px' : $isopen ? '0px' : '30px')};
  overflow: hidden;
  display: flex;
  justify-content: ${({$staticheader, $showCloseButton}) => ($staticheader && $showCloseButton ? 'flex-end' : 'space-between')};
  align-items: center;
  transition: all 0.3s ease;
`;

const AccordionContent = styled.div`
    overflow: hidden;
    animation: ${({ $isopen }) => ($isopen ? fadeIn : fadeOut)} 0.3s ease forwards;

    /* Hide scrollbar for Webkit browsers */
    &::-webkit-scrollbar {
      display: none; /* Hides the scrollbar */
    }

    /* Hide scrollbar for Firefox */
    scrollbar-width: none;
`;

const DoctorDiscussionQuickNotes = ({
    quickNotesState,
    toggleQuickNotesState
}) => {
    return (
        <AccordionContainer className={``}>
            <AccordionHeader 
              $isopen={quickNotesState} 
            >
                <>
                    <p className={`capitalize p-0 m-0 leading-none whitespace-nowrap text-[14px] text-[#272d2d] font-medium`}>
                        Quick Notes
                    </p>
                        {quickNotesState ? 
                        <ButtonWithCenterIcon 
                            parentWidth={'w-max flex items-center justify-center'} 
                            width={'w-[30px]'} 
                            height={'h-[30px]'} 
                            icon={CloseFullscreenRoundedIcon} 
                            iconFontSize={16} 
                            iconColor={'#444'}
                            bgColor={'bg-transparent'}
                            clickHandler={() => {toggleQuickNotesState(false)}} 
                        />
                        :
                        <ButtonWithCenterIcon 
                            parentWidth={'w-max flex items-center justify-center'} 
                            width={'w-[30px]'} 
                            height={'h-[30px]'} 
                            icon={OpenInFullRoundedIcon} 
                            iconFontSize={16} 
                            iconColor={'#444'}
                            bgColor={'bg-transparent'}
                            clickHandler={() => {toggleQuickNotesState(true)}} 
                        />
                    }
                </>
            </AccordionHeader>
            <AccordionContent $isopen={quickNotesState}>
                <div className='rounded-[30px] h-full overflow-visible flex flex-col justify-center items-center'>
                    <div className='scrollable-content px-4 w-full h-full flex flex-col justify-start items-start overflow-auto'>
                        <div className='w-full h-max flex flex-col items-center justify-start text-[12px]'>
                            <div className='w-full h-max flex flex-col justify-start items-start gap-1 py-2'>
                                <p className='text-[#666]'>Heart MRI</p>
                                <div className='w-[80%] h-[100px] object-cover object-center rounded-[30px] overflow-hidden'>
                                    <LightGallery
                                        elementClassNames='w-full h-full'
                                        speed={200}
                                        plugins={[lgThumbnail, lgZoom]}
                                    >
                                        <a href={xRayImage} data-src={xRayImage}>
                                            <img className='relative w-full h-full object-cover object-center' src={xRayImage} alt="x-ray" />
                                        </a>
                                    </LightGallery>
                                </div>
                            </div>
                            <div className='w-full h-max flex flex-col justify-start items-start gap-1 py-2'>
                                <p className='text-[#666]'>Heart MRI</p>
                                <div className='w-[80%] h-[100px] object-cover object-center rounded-[30px] overflow-hidden'>
                                    <LightGallery
                                        elementClassNames='w-full h-full'
                                        speed={200}
                                        plugins={[lgThumbnail, lgZoom]}
                                    >
                                        <a href={xRayImage} data-src={xRayImage}>
                                            <img className='relative w-full h-full object-cover object-center' src={xRayImage} alt="x-ray" />
                                        </a>
                                    </LightGallery>
                                </div>
                            </div>
                            <div className='w-full h-max flex flex-col justify-start items-end py-2'>
                                <p className='text-[#666]'>Notes</p>
                            </div>
                            <div className='w-full h-max flex flex-col justify-start items-start gap-1 py-2'>
                                <p className='text-[#666]'>Heart MRI</p>
                                <div className='w-[80%] h-[100px] object-cover object-center rounded-[30px] overflow-hidden'>
                                    <LightGallery
                                        elementClassNames='w-full h-full'
                                        speed={200}
                                        plugins={[lgThumbnail, lgZoom]}
                                    >
                                        <a href={xRayImage} data-src={xRayImage}>
                                            <img className='relative w-full h-full object-cover object-center' src={xRayImage} alt="x-ray" />
                                        </a>
                                    </LightGallery>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-full justify-between items-center'>
                         <ButtonWithCenterIcon 
                             parentWidth={'w-max flex items-center justify-center'} 
                             width={'w-[40px]'} 
                             height={'h-[40px]'} 
                             icon={PostAddOutlinedIcon} 
                             iconFontSize={18} 
                             iconColor={'#fff'}
                             bgColor={'bg-[#272d2d]'}
                             clickHandler={() => {}} 
                         />
                         <div className='w-full h-full bg-[#fff] rounded-[30px] pl-2 flex justify-between items-center'>
                            <CustomTextField
                                size="small"
                                label=""
                                placeholder='Enter Message'
                                multiline
                                inputProps={{ maxLength: 200 }}
                            />
                             <ButtonWithCenterIcon 
                                 parentWidth={'w-max flex items-center justify-center'} 
                                 width={'w-[40px]'} 
                                 height={'h-[40px]'} 
                                 icon={SendOutlinedIcon} 
                                 iconFontSize={18} 
                                 iconColor={'#444'}
                                 bgColor={'bg-[#f3ff47]'}
                                 clickHandler={() => {}} 
                             />
                         </div>
                    </div>
                </div>
            </AccordionContent>
        </AccordionContainer>
    )
}

export default DoctorDiscussionQuickNotes
