export const getCurrentDateString = () => {
    // Get the current date
    const currentDate = new Date();

    // Extract month, day, and year
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-based, so add 1
    const day = String(currentDate.getDate()).padStart(2, '0');
    const year = currentDate.getFullYear();

    // Construct the date string in MM/DD/YYYY format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate
}