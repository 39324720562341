import React, { Fragment, useCallback, useEffect } from 'react'
import styled from 'styled-components';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { changeNavState } from '../slices/navSlice'; // Importing navSlices for state in redux
import TabsComponent from './TabsComponent';
import { changeDrawerState } from '../slices/drawerSlice';
import { changeFormDrawerState } from '../slices/formDrawerSlice';

const menuArray = [
    {icon:  CottageOutlinedIcon , name: 'overview'},
    {icon: DescriptionOutlinedIcon , name: 'notes'},
    {icon:  FolderOpenOutlinedIcon , name: 'docs'},
    {icon:  ScienceOutlinedIcon , name: 'labs'},
    {icon:  ElevatorOutlinedIcon , name: 'imaging'},
    {icon:  MarkUnreadChatAltOutlinedIcon , name: 'consultation'},
    {icon:  SmsOutlinedIcon , name: 'communication'},
]

const MenuBar = () => {
  const navState = useSelector((state) => state.navSlice.navState); // Getting navstate value from the store
  const drawerState = useSelector((state) => state.drawerSlice.drawerState);
  const drawerStateDispatch = useDispatch();
  const formDrawerStateDispatch = useDispatch();
	const navStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux

  const updateData = useCallback((item) => {
		// Dispatch an action to update the state with the value of nav bar list item which is being clicked
		navStateDispatch(changeNavState(item));
    if (item.toLowerCase() === 'docs') {
      drawerStateDispatch(changeDrawerState(true));
      formDrawerStateDispatch(changeFormDrawerState(false));
    } else {
      drawerStateDispatch(changeDrawerState(false));
      formDrawerStateDispatch(changeFormDrawerState(false));
    }
    const element = document.getElementById(`menuListItem${item.toLowerCase()}`);
    if (element) {
      const area = element.getBoundingClientRect();
      const activeTabIndicator = document.getElementById('activeMenuIndicator');
      if (activeTabIndicator) {
        activeTabIndicator.style.position="fixed";
        activeTabIndicator.style.top = `${area.top}px`;
        activeTabIndicator.style.left = `${area.left}px`;
        activeTabIndicator.style.width = `${area.width}px`;
        activeTabIndicator.style.transition = "all 0.5s"
      }
    }
	}, [navStateDispatch]);

  useEffect(() => {
    setTimeout(() => {
      updateData(navState.toLowerCase());
    }, 500);
  }, [])

  useEffect(() => {
    updateData(navState.toLowerCase());
  }, [navState, updateData])

  return (
    <div className='shadow-sm text-[12px] mx-auto rounded-[100px] p-[1px] bg-white overflow-hidden'>
      <ul className='relative flex justify-center items-center gap-0 z-[999]'>
        <TabsComponent menuArray={menuArray} tabState={navState} onClickListener={updateData} tabItemIdPrefix={'menuListItem'} />
        <div id='activeMenuIndicator' className={`absolute h-[34px] z-[99] bg-[#F3FF47] rounded-[100px] transition duration-[0.3s]`}>
        </div> 
      </ul>
    </div>
  )
}

export default MenuBar
