import React, { useState } from 'react'
import PatientVitals from './PatientVitals'
import PatientAllergies from './PatientAllergies';
import PatientImplants from './PatientImplants';
import PatientSocial from './PatientSocial';

const ProfileReportContent = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className='-mt-[30px] w-full h-3/4 z-[999] flex flex-col justify-start items-start gap-[2px]'>
      <PatientVitals onClick={() => handleAccordionClick(0)} isOpen={openAccordionIndex === 0}/>
      <PatientAllergies onClick={() => handleAccordionClick(1)} isOpen={openAccordionIndex === 1}/>
      <PatientImplants onClick={() => handleAccordionClick(2)} isOpen={openAccordionIndex === 2}/>
      <PatientSocial onClick={() => handleAccordionClick(3)} isOpen={openAccordionIndex === 3} />
    </div>
  )
}

export default ProfileReportContent
