import React, { useState, useRef, forwardRef, useEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { ReportIcon, FunnelIcon, MedsIcon } from '../../assets/icons/MedicalIcons';
import moment from 'moment';
import TimelineWrapper from '../TimelineWrapper';
import CustomDrawer from '../CustomDrawer';
import DocsContentWrapper from './DocsContentWrapper';

const disgnosisArray = [
    {title : 'heart failure', date: '20.12.2021', treatment: 2, operation: 0},
    {title : 'valve disease', date: '20.12.2021', treatment: 2, operation: 1},
    {title : 'arrhythmia', date: '20.12.2021', treatment: 2, operation: 0},
    {title : 'angina', date: '20.12.2021', treatment: 2, operation: 0},
]

const data = [
  {date: '10/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '11/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '12/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '13/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '14/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '15/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '18/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '20/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '25/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '30/01/2023', value: 30, data : [ {type: 'labs', label: 'Binimentinib', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '24/03/2023', value: 30, data : [ {type: 'docs', label: 'Visit', icon: <ReportIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '05/04/2023', value: 30, data : [ {type: 'docs', label: 'Visit', icon: <ReportIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '01/06/2023', value: 30, data : [ 
    {type: 'docs', label: 'visit', icon: <ReportIcon width={10} height={10} fill={'#fff'} />}, 
    {type: 'imaging', label: 'ECG', icon: <FunnelIcon width={10} height={10} fill={'#fff'} />} 
  ]},
  {date: '01/09/2023', value: 30, data : [ {type: 'labs', label: 'Aspirin', icon: <MedsIcon width={10} height={10} fill={'#fff'} />} ]},
  {date: '01/12/2023', value: 30, data : [ {type: 'docs', label: 'Visit', icon: <ReportIcon width={10} height={10} fill={'#fff'} />} ]},
]

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} dx={10} textAnchor="end" fontSize={10} fill="#666">
        {(payload.value + 1) % 3 === 0 ? payload.value + 1 : ''}
      </text>
    </g>
  );
};

const OverviewTabContent = forwardRef(({ openDrawer,toggleDrawer }, ref) => {
  const [adjustedData, setAdjustedData] = useState([]);
  var regex = /\s+(\w)?/gi;
  const containerRef = useRef();
  const drawerState = useSelector((state) => state.drawerSlice.drawerState);
  const yearTimelineState = useSelector((state) => state.yearTimelineReducer.yearTimelineState.yearTimelineState); // Getting state value from the store
  const yearTimelineDuration = useSelector((state) => state.yearTimelineReducer.yearTimelineDurationState.yearTimelineDurationState); // Getting state value from the store
  const monthsInYear = Array.from({ length: yearTimelineDuration[1]+1 }, (_, i) => i + 1); // Array of numbers from 1 to 12
  const currentDate = new Date(yearTimelineState).getDate() + '.' + new Date(yearTimelineState).getMonth() + '.' + new Date(yearTimelineState).getFullYear();
  
  function parseDate(dateString) {
    // Split the string into day, month, and year components
    const [day, month, year] = dateString.split('/').map(Number);
  
    // Construct a new Date object
    return new Date(year, month - 1, day);
  }

  function convertString(string) {
    var output = string.toLowerCase().replace(regex, function(match, letter) {
        return letter.toUpperCase();
    });

    return output
  }

  const CustomLabel = props => {
    if (!adjustedData[props.index].clustered) {
      return (
        adjustedData[props.index].data.map((item, i) => {
          return (
            <foreignObject 
              key = {`${item.label}${i}`}
              className="relative label-wrapper overflow-visible" 
              width = {1} 
              height = {22} 
              x = {props.x - 12} 
              y = {i === 0 ? props.y - 20 : props.y - ((i+1)*24)}
              onClick={() => toggleDrawer(true, item.type)}
            >
              <div xmlns="http://www.w3.org/1999/xhtml" 
                className='absolute top-0 left-0 w-max h-full rounded-[20px] bg-[#fff] p-[2px] flex justify-between items-center'
              >
                <div className="custom-label bg-[#272d2d] rounded-full w-max h-full p-[5px] flex justify-center items-center peer cursor-pointer">
                  {item.icon}
                </div>
                <p className='w-0 text-[0px] peer-hover:px-[5px] peer-hover:text-[10px] peer-hover:w-max transition-all duration-200'>
                  {item.label}
                </p>
              </div>
            </foreignObject>
          )
        })
      );
    } else {
      return (
        <foreignObject 
          className="relative label-wrapper overflow-visible z-10 hover:z-50 transition-all" 
          width = {1} 
          height = {22} 
          x = {props.x - 12} 
          y = {props.y - 20}
          onClick={() => {}}
        >
          <div xmlns="http://www.w3.org/1999/xhtml" 
            className='absolute top-0 left-0 w-max h-full rounded-[20px] bg-[#fff] p-[2px] flex justify-between items-center'
          >
            <div className="custom-label bg-[#f3ff47] rounded-full w-max h-full p-[5px] flex justify-center items-center peer cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="10" height="10" fill='#272d2d' >
                <path d="M418.4 157.9c35.3-8.3 61.6-40 61.6-77.9c0-44.2-35.8-80-80-80c-43.4 0-78.7 34.5-80 77.5L136.2 151.1C121.7 136.8 101.9 128 80 128c-44.2 0-80 35.8-80 80s35.8 80 80 80c12.2 0 23.8-2.7 34.1-7.6L259.7 407.8c-2.4 7.6-3.7 15.8-3.7 24.2c0 44.2 35.8 80 80 80s80-35.8 80-80c0-27.7-14-52.1-35.4-66.4l37.8-207.7zM156.3 232.2c2.2-6.9 3.5-14.2 3.7-21.7l183.8-73.5c3.6 3.5 7.4 6.7 11.6 9.5L317.6 354.1c-5.5 1.3-10.8 3.1-15.8 5.5L156.3 232.2z"/>
              </svg>
            </div>
            <p className='w-0 text-[0px] peer-hover:px-[5px] peer-hover:text-[10px] peer-hover:w-max transition-all duration-200'>
              Expand the timeline
            </p>
          </div>
        </foreignObject>
      )
    }
  };

  // Function to cluster close data items based on a threshold
  function clusterCloseDataItems(data, thresholdDays) {

    // If the threshold is less than or equal to 2, return data with clustered: false
    if (thresholdDays <= 1) {
      return data.map((item) => ({
        ...item,
        position: calculatePosition(item.date),
        clustered: false, // No clustering
      }));
    }

    const adjustedData = [];
    let currentCluster = [];

    const addClusterToAdjustedData = (cluster) => {
      if (cluster.length > 1) {
        // Merge cluster into a single item
        adjustedData.push(mergeCluster(cluster));
      } else {
        // Add individual item with clustered: false
        const item = cluster[0];
        adjustedData.push({
          ...item,
          position: calculatePosition(item.date),
          clustered: false, // Not part of a merged cluster
        });
      }
    };
    
    for (let i = 0; i < data.length; i++) {
      const currentItem = data[i];
      const currentDate = parseDate(currentItem.date);

      if (currentCluster.length === 0) {
        // Start a new cluster
        currentCluster.push(currentItem);
      } else {
        const lastItem = currentCluster[currentCluster.length - 1];
        const lastDate = parseDate(lastItem.date);

        const diffInDays = Math.abs((currentDate - lastDate) / (1000 * 60 * 60 * 24));

        if (diffInDays <= thresholdDays) {
          // If within threshold, add to the cluster
          currentCluster.push(currentItem);
        } else {
          // If not within threshold, merge or add individual item
          addClusterToAdjustedData(currentCluster);
          currentCluster = [currentItem]; // Start a new cluster
        }
      }
    }

    // Ensure the last cluster is handled
    if (currentCluster.length > 0) {
      addClusterToAdjustedData(currentCluster);
    }

    return adjustedData;
  }

  // Function to merge a cluster into a single item
  function mergeCluster(cluster) {
    const dates = cluster.map(item => parseDate(item.date));
    const minDate = new Date(Math.min(...dates.map(d => d.getTime()))); // First date
    const maxDate = new Date(Math.max(...dates.map(d => d.getTime()))); // Last date

    // Calculate midpoint date for the new merged item
    const midDate = new Date((minDate.getTime() + maxDate.getTime()) / 2);
    
    // Merge the 'data' arrays from the entire cluster
    const mergedData = cluster.flatMap(item => item.data);

    // Create a new merged item with position and 'clustered' property
    const mergedItem = {
      date: `${midDate.getDate()}/${midDate.getMonth() + 1}/${midDate.getFullYear()}`,
      value: cluster.reduce((acc, item) => acc + item.value, 0), // Sum values
      position: calculatePosition(
        `${midDate.getDate()}/${midDate.getMonth() + 1}/${midDate.getFullYear()}`
      ),
      data: mergedData,
      clustered: true, // Indicates it's a merged item
    };

    return mergedItem;
  }

  const calculatePosition = (date) => {
    const [day, month] = date.split('/');
    const fractionalValue = (parseInt(day) - 1) / 30; // Assuming 30 days in a month
    return parseInt(month-1) + fractionalValue;
  };

  useEffect(() => {
    const newAdjustedData = data
      .filter((item) => parseDate(item.date).getMonth() <= yearTimelineDuration[1])
      .map((item) => ({
        ...item,
        position: calculatePosition(item.date), // Example transformation
      }));

    
    const mergedData = clusterCloseDataItems(newAdjustedData, yearTimelineDuration[1]+1);

    setAdjustedData(mergedData);
  }, [yearTimelineDuration]);

  return (
      <div className='relative w-full min-h-0 flex-1 flex flex-col gap-[2px] justify-start items-start'>
          {
              disgnosisArray.map((item, i) => {
                  return <TimelineWrapper key={item.title} id={convertString(item.title)} title={item.title} date={currentDate} treatment={item.treatment} operation={item.operation} active={i === 0 ? true : false}>
                        <div className='w-full h-full' id={`timeline${i}`} ref={containerRef}>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={adjustedData} barGap={0} margin={{right: 60, top: 0, bottom: 0, left: 20}}>
                              
                              <XAxis 
                                dataKey="position"
                                domain={['auto','auto']} 
                                scale='time' 
                                ticks={monthsInYear} 
                                interval={0}
                                type='number' 
                                axisLine={false} 
                                mirror={true} 
                                tick={<CustomizedAxisTick />}
                                tickFormatter = {(date) => moment(date).format('MMM DD')}
                              />
                              <YAxis domain={[0, 600]} axisLine={false} tickLine={false} tick={false} hide={true} />

                              <Bar barSize={0.75} isAnimationActive={false} onMouseOver={() => {}} minPointSize={20} fill="#bbb">
                                <LabelList content={<CustomLabel />} />
                              </Bar>

                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                  </TimelineWrapper>
              })
          }
          <CustomDrawer isOpen={openDrawer} toggleDrawer={toggleDrawer} ref={ref} renderArrow={drawerState}>
              <DocsContentWrapper />
          </CustomDrawer>
      </div>
  )
});

export default OverviewTabContent
