import React from 'react'
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import ButtonWithCenterIcon from '../ButtonWithCenterIcon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { TextField } from '@mui/material';
import styled from 'styled-components';

// Custom-styled TextField with no outline on focus
const CustomTextField = styled(TextField)`
    width: 100%;
    & .MuiOutlinedInput-root {
        background-color: #94a3b81a;
        font-size: 12px;
        width: 100%;
        /* Override the default border on focus */
        &.Mui-focused {
            outline: none; /* No outline */
            border-color: transparent; /* Optional: make border transparent */
            box-shadow: none; /* Remove shadow or glow effects */
        }

        /* Remove hover effects */
        &:hover {
            border-color: transparent; /* Optional: make border transparent */
        }
    }
    
    & .MuiOutlinedInput-notchedOutline {
        outline: none;
        border: none;
    }
`;

const DiagnosisComponent = () => {
    return <div className='w-full h-max flex flex-col justify-start items-start gap-2 rounded-[30px] bg-[#fff] p-4'>
        <h2 className='w-full text-[14px] capitalize font-medium leading-[1]'>Diagnosis</h2>
        <div className='w-full flex flex-col justify-start items-start gap-2'>
            <div className='w-full flex justify-start items-center gap-2 text-[12px] border-b-[2px] border-[#ddd]'>
                <ButtonWithCenterIcon 
                    parentWidth={'w-max flex items-center justify-center border-2 h-max'} 
                    width={'w-[12px]'} 
                    height={'h-[12px]'} 
                    icon={CloseRoundedIcon} 
                    iconFontSize={12} 
                    iconColor={'#444'}
                    bgColor={'bg-transparent'}
                    clickHandler={() => {}} 
                />
                <Button 
                    className='!text-[10px] w-max' 
                    variant="contained" 
                    disableElevation
                    size="small"
                    sx={{ padding: '0', fontWeight: '600' }}
                >
                    Z01.479
                </Button>
                <p className=''>Encounter for routine cardiovascular examination without abnormal findings.</p>
                <FormControlLabel 
                    control={
                        <Checkbox 
                            label="Label" 
                            size="small" 
                            sx={{ fontSize: '12px', padding: '0', '& .MuiCheckbox-sizeSmall': { width: 5, height: 5 } }} 
                        />
                    } 
                    label="Save in chart" 
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: 10, whiteSpace: 'nowrap', fontWeight: '600', color: '#666' } }}
                />
                
            </div>
            <div className='w-full flex flex-col justify-start items-start gap-1 text-[12px] font-medium'>
                <p className='text-[#999]'>Code and name</p>
                <div className='w-full flex justify-start items-center gap-5 text-[12px]'>
                    <FormControl sx={{ m: 0, p: 0, width: '100%', fontSize: 12, border: 'none' }} size="small">
                        <Select
                            displayEmpty
                            id="demo-select-small"
                            value={''}
                            sx={{ fontSize: 12, color: '#999', fontWeight: '600', backgroundColor: '#94a3b81a', border: 'none', outline: 'none' }}
                            onChange={() => {}}
                            IconComponent = {ExpandMoreRoundedIcon}
                        >
                            <MenuItem sx={{ color: '#999' }} value="" selected disabled>
                                <em>Select</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                label="Label" 
                                size="small" 
                                sx={{ fontSize: '12px', padding: '0', '& .MuiCheckbox-sizeSmall': { width: 5, height: 5 } }} 
                            />
                        } 
                        label="Save in chart" 
                        sx={{ '& .MuiFormControlLabel-label': { fontSize: 10, whiteSpace: 'nowrap', fontWeight: '600', color: '#666' } }}
                    />
                </div>
            </div>
        </div>
    </div>
}

const OrdersComponent = () => {
    return <div className='w-full h-max flex flex-col justify-start items-start gap-2 rounded-[30px] bg-[#fff] p-4'>
        <div className='w-full flex items-center justify-between'>
            <h2 className='w-full text-[14px] capitalize font-medium leading-[1]'>Orders</h2>
            <ButtonWithCenterIcon 
                parentWidth={'w-max flex items-center justify-center'} 
                width={'w-max'} 
                height={'h-max'}
                iconFontSize={16} 
                iconColor={'#444'}
                bgColor={'bg-transparent'}
                clickHandler={() => {}} 
                icon={AddRoundedIcon}
            />
        </div>
        <div className='w-full flex justify-center items-center gap-2'>
            <div className='w-full flex flex-col justify-start items-start gap-1 text-[12px] font-medium'>
                <p className='text-[#999]'>Department</p>
                <div className='w-full flex justify-start items-center gap-5 text-[12px]'>
                    <FormControl sx={{ m: 0, p: 0, width: '100%', fontSize: 12 }} size="small">
                        <Select
                            displayEmpty
                            id="demo-select-small"
                            value={0}
                            sx={{ fontSize: 12, color: '#272d2d', fontWeight: '500', backgroundColor: '#94a3b81a' }}
                            onChange={() => {}}
                            IconComponent = {ExpandMoreRoundedIcon}
                        >
                            <MenuItem value={0} selected>Cardiology</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='w-full flex flex-col justify-start items-start gap-1 text-[12px] font-medium'>
                <p className='text-[#999]'>Procedure</p>
                <div className='w-full flex justify-start items-center gap-5 text-[12px]'>
                    <FormControl sx={{ m: 0, p: 0, width: '100%', fontSize: 12 }} size="small">
                        <Select
                            displayEmpty
                            id="demo-select-small"
                            value={0}
                            sx={{ fontSize: 12, color: '#272d2d', fontWeight: '500', backgroundColor: '#94a3b81a' }}
                            onChange={() => {}}
                            IconComponent = {ExpandMoreRoundedIcon}
                        >
                            <MenuItem value={0} selected>ECG</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    </div>
}

const PrescriptionComponent = () => {
    return <div className='w-full h-max flex flex-col justify-start items-start gap-2 rounded-[30px] bg-[#fff] p-4'>
        <h2 className='w-full text-[14px] capitalize font-medium leading-[1]'>Prescription</h2>
        <div className='w-full flex justify-center items-center gap-2 pb-2 border-b-[2px] border-[#ddd]'>
            <div className='w-full flex flex-col justify-start items-start gap-1 text-[12px] font-medium'>
                <p className='text-[#999]'>Medication name</p>
                <div className='w-full flex justify-start items-center gap-5 text-[12px]'>
                    <FormControl sx={{ m: 0, p: 0, width: '100%', fontSize: 12 }} size="small">
                        <Select
                            displayEmpty
                            id="demo-select-small"
                            value={0}
                            sx={{ fontSize: 12, color: '#272d2d', fontWeight: '500', backgroundColor: '#94a3b81a' }}
                            onChange={() => {}}
                            IconComponent = {ExpandMoreRoundedIcon}
                        >
                            <MenuItem value={0} selected>Crestor</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='w-1/4 flex flex-col justify-start items-start gap-1 text-[12px] font-medium'>
                <p className='text-[#999]'>Dose</p>
                <CustomTextField
                    size="small"
                    label=""
                    placeholder=''
                    value={'20 mg'}
                    inputProps={{ maxLength: 20 }}
                />
            </div>
            <div className='w-1/4 flex flex-col justify-start items-start gap-1 text-[12px] font-medium'>
                <p className='text-[#999]'>Pills per day</p>
                <CustomTextField
                    size="small"
                    label=""
                    placeholder=''
                    value={1}
                    inputProps={{ maxLength: 20 }}
                />
            </div>
        </div>
        <div className='w-full flex flex-col justify-start items-start gap-1 text-[12px] font-medium'>
            <p className='text-[#999]'>Recommendations</p>
            <CustomTextField
                size="small"
                label=""
                rows={3}
                placeholder='Enter Message'
                value={'Once a day. in the morning, tablet should be swallowed as whole.'}
                multiline
                inputProps={{ maxLength: 200 }}
            />
        </div>
    </div>
}

const AdditionalDocumentationComponent = () => {
    return <div className='w-full h-full flex flex-col justify-start items-start gap-2 rounded-[30px] bg-[#fff] p-4'>
        <h2 className='w-full text-[14px] capitalize font-medium leading-[1]'>Additional Documentation</h2>
        <div className='w-full flex flex-wrap justify-start items-center gap-3 p-2'>
            <FormControlLabel 
                control={
                    <Checkbox 
                        label="Label" 
                        size="small" 
                        sx={{ fontSize: '12px', padding: '0', '& .MuiCheckbox-sizeSmall': { width: 5, height: 5 } }} 
                    />
                } 
                label="Sick leave" 
                sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: 12, whiteSpace: 'nowrap', fontWeight: '500', color: '#272d2d' }, 
                    display: 'flex', 
                    gap: '5px',
                    justifyContent: 'center',
                    alignItems: 'center' 
                }}
            />
            <FormControlLabel 
                control={
                    <Checkbox 
                        label="Label" 
                        size="small" 
                        sx={{ fontSize: '12px', padding: '0', '& .MuiCheckbox-sizeSmall': { width: 5, height: 5 } }} 
                    />
                } 
                label="Hospital referral" 
                sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: 12, whiteSpace: 'nowrap', fontWeight: '500', color: '#272d2d' }, 
                    display: 'flex', 
                    gap: '5px',
                    justifyContent: 'center',
                    alignItems: 'center' 
                }}
            />
            <FormControlLabel 
                control={
                    <Checkbox 
                        label="Label" 
                        size="small" 
                        sx={{ fontSize: '12px', padding: '0', '& .MuiCheckbox-sizeSmall': { width: 5, height: 5 } }} 
                    />
                } 
                label="Qualfication card for hospitalisation" 
                sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: 12, whiteSpace: 'nowrap', fontWeight: '500', color: '#272d2d' }, 
                    display: 'flex', 
                    gap: '5px',
                    justifyContent: 'center',
                    alignItems: 'center' 
                }}
            />
            <FormControlLabel 
                control={
                    <Checkbox 
                        label="Label" 
                        size="small" 
                        sx={{ fontSize: '12px', padding: '0', '& .MuiCheckbox-sizeSmall': { width: 5, height: 5 } }} 
                    />
                } 
                label="Maternity leave" 
                sx={{ 
                    '& .MuiFormControlLabel-label': { fontSize: 12, whiteSpace: 'nowrap', fontWeight: '500', color: '#272d2d' }, 
                    display: 'flex', 
                    gap: '5px',
                    justifyContent: 'center',
                    alignItems: 'center' 
                }}
            />
        </div>
    </div>
}

const ConsultationPrescriptionForm = ({ openModal }) => {
    return (
        <div className='w-full h-full flex flex-col gap-2 justify-start items-start'>
            <DiagnosisComponent />
            <OrdersComponent />
            <PrescriptionComponent />
            <AdditionalDocumentationComponent />
            <div className='w-full h-max flex justify-center items-center gap-2 !text-[12px] px-2 pb-2'>
                <Button 
                    className='!text-[12px] w-full hover:bg-gradient-to-b from-blue-400 to-blue-700 hover:text-[#fff]' 
                    variant="contained" 
                    size="small" 
                    disableElevation 
                    sx={{ backgroundColor: '#fff', color: '#272d2d', shadow: '0 0 10px rgba(0,0,0,0.1)' }}
                >
                    Print
                </Button>
                <Button 
                    className='!text-[12px] w-full bg-gradient-to-b from-blue-400 to-blue-700' 
                    variant="contained" 
                    disableElevation
                    size="small"
                    onClick={() => {openModal();}}
                >
                    Sign & Save
                </Button>
            </div>
        </div>
    )
}

export default ConsultationPrescriptionForm
