import React from 'react'

const ReportIconComponent = ({ 
    id,
    bg_color, 
    Icon, 
    text, 
    fontColor,  
    activeBgColor,
    reportId,
    activeReport,
    changeActiveReport,
    isDisabled,
    defaultChecked 
}) => {

    return (
        <div className={`
            rounded-full h-[52px] w-[52px] leading-none flex justify-center items-center gap-1 cursor-pointer
            ${bg_color ? activeReport === reportId ? activeBgColor : bg_color : 'bg-transparent'}
            ${fontColor ? fontColor : 'text-[#444]'}
        `} onClick={() => changeActiveReport(reportId)}
        >
            {Icon ? <Icon style={{ fontSize: '16px', color: activeReport === reportId ? '#fff' : '#272d2d' }} /> : ''}
            {text ? <p className='bg-[#f3ff47] px-2 py-1 rounded-full text-[10px]'>{text}</p> : ''}
        </div>
    )
}

export default ReportIconComponent
