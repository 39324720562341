import React, { useState } from 'react'
import TitleSubtitleComponent from '../reusableComponents/TitleSubtitleComponent'
import { getCurrentDateString } from '../../utils/getCurrentDateString'
import CustomInfoAccordion from '../patientComponents/CustomInfoAccordion'
import CustomChips from '../CustomChips'
import ButtonWithCenterIcon from '../ButtonWithCenterIcon'
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import doctorImage01 from '../../assets/images/doctorImage01.png'
import doctorImage02 from '../../assets/images/doctorImage02.png'
import doctorImage03 from '../../assets/images/doctorImage03.png'
import doctorImage04 from '../../assets/images/doctorImage04.png'
import VideoConferenceCard from '../reusableComponents/VideoConferenceCard'
import LightModeIcon from '@mui/icons-material/LightMode';

const doctorConferenceData = [
    {name: 'dr. maccami', type: 'cardiologist', icon: doctorImage01, image: doctorImage01, },
    {name: 'dr. rousey', type: 'cardiologist', icon: doctorImage02, image: doctorImage02, },
    {name: 'dr. green', type: 'surgeon', icon: doctorImage03, image: doctorImage03, },
    {name: 'dr. franki', type: 'head physician', icon: doctorImage04, image: doctorImage04, },
]

const DiscussionRoomSidebar = () => {
    const [roomState, setRoomState] = useState(true);

    return (
        <div className='w-full h-auto min-h-0 flex-1 flex flex-col gap-2'>
            <TitleSubtitleComponent 
                parentFlexGap = {'gap-[5px]'}
                parentPadding = {'px-3'}
                titleText={'Discussion Room'} 
                subText={getCurrentDateString()} 
                titleFontSize={'text-[16px]'} 
                subTitleFontSize={'text-[12px]'} 
                titleFontWeight = {'font-[600]'}
                subTitleFontWeight = {'font-[400]'}
                subTitleTextColor = {'text-[#666]'}
            />
            <CustomInfoAccordion 
                title="" 
                onClick={() => {setRoomState(!roomState)}} 
                isopen={roomState} 
                parentContainerClasses={'w-full h-auto flex-1 min-h-0 flex flex-col !bg-transparent'}
                maxContentHeight={true}
                staticHeaderComponent={true}
                showCloseButton={false}
                buttonClickHandler={() => {setRoomState(!roomState)}} 
                accordionHeaderColor = {'#ececec'}
                keepHeaderRounded = {true}
                parentShadow={'none'}
                headerComponent={
                    <>
                        <CustomChips bgColor={'bg-transparent'} shadow={'shadow-none'} padding={'py-2'}>
                            <TitleSubtitleComponent 
                                parentFlexGap = {'gap-[5px]'}
                                titleText={'videoconference'} 
                                subText={'5 participants'} 
                                titleFontSize={'text-[14px]'} 
                                subTitleFontSize={'text-[10px]'} 
                                titleFontWeight = {'font-[400]'}
                                subTitleFontWeight = {'font-[400]'}
                                subTitleTextColor = {'text-[#666]'}
                                showHeadingOnTop={true}
                            />
                        </CustomChips>
                        {roomState ? 
                            <ButtonWithCenterIcon 
                                parentWidth={'w-max flex items-center justify-center'} 
                                width={'w-[30px]'} 
                                height={'h-[30px]'} 
                                icon={CloseFullscreenOutlinedIcon} 
                                iconFontSize={14} 
                                iconColor={'#444'}
                                bgColor={'bg-transparent'}
                                clickHandler={() => {setRoomState(!roomState)}} 
                            />
                            :
                            <ButtonWithCenterIcon 
                                parentWidth={'w-max flex items-center justify-center'} 
                                width={'w-[30px]'} 
                                height={'h-[30px]'} 
                                icon={OpenInFullRoundedIcon} 
                                iconFontSize={14} 
                                iconColor={'#444'}
                                bgColor={'bg-transparent'}
                                clickHandler={() => {setRoomState(!roomState)}} 
                            />
                        }
                    </>
                } 
            >
                {
                    doctorConferenceData.map((item, i) => (
                        <VideoConferenceCard
                            key={`${item.name}${i}`}
                            parentBgColor={'bg-[#ececec]'}
                            name={item.name}
                            type={item.type}
                            icon={item.icon}
                            image={item.image}
                            buttonIcon={LightModeIcon}
                            buttonIconBgColor={'bg-[#fff]'}
                            roundedSize={'rounded-[30px]'}
                        />
                    ))
                }
            </CustomInfoAccordion>
        </div>
    )
}

export default DiscussionRoomSidebar
